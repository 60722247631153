import { type } from 'os';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateInput, Input, AsyncSelect, LookupSelector, SekeletonLoading } from '..';
import { useGetAllDropdownByCategoryQuery, useLazyGetAllDropdownByCategoryQuery } from '../../redux-tools/services/dropdown/dropdown.service';
import { FormDataHook, FormDataState } from '../../redux-tools/slices/form-data/form-data.interface';
import { formDataActions, formDataSelector } from '../../redux-tools/slices/form-data/form-data.slices';
import { getProperty, setProperty } from '../../_module/helper/objByString';
import { SelectOptionPorps } from '../input/input.interface';
import GenerateFormGrid from './generate-form.grid';
import { GenerateFormProps } from './generate-form.interface';
import classNames from 'classnames';

function cekIncludeType(types: Array<GenerateFormProps['type']>, type: GenerateFormProps['type']) {
	return types.some(v => v === type);
}
export default function GenerateForm (props: GenerateFormProps) {
	const formDataState: FormDataState = useSelector(formDataSelector);
	const [getDataDropdown, { isLoading, isFetching }] = useLazyGetAllDropdownByCategoryQuery();
	const disp = useDispatch();
	const [dataSelector, setDataSelector] = useState<Array<any>>([]);
	if (!props.name) throw new Error('property name on props cant be null or undefined');
	const isDotedName = useMemo<boolean>(() => ((props.name ? props.name : '').split('.').length > 1 ? true : false), [props.name]);
	const [isFocus, setIsFocus] = useState(false);
	const err: { isErr: boolean; errMsg: string } = useMemo(() => {
		let validation: { isErr: boolean; errMsg: string } = { isErr: false, errMsg: '' };
		if (props.validator) {
			const [isErr, errMsg] = props.validator(getValue(), props.name || '');
			validation = { isErr: !isErr as boolean, errMsg: errMsg as string };
		}
		return validation;
	}, [getValue, formDataState.data[props.name]]);

	useEffect(
		function () {
			let validation: { isErr: boolean; errMsg: string } = { isErr: false, errMsg: '' };
			if (props.validator) {
				const [isErr, errMsg] = props.validator(getValue(), props.name || '');
				validation = { isErr: !isErr as boolean, errMsg: errMsg as string };
			}
			if (props.manualData) {
				props.setValidation?.({ [props.name as any]: validation });
			} else {
				disp(formDataActions.setValidation({ [props.name as any]: validation }));
			}
		},
		[props.value, formDataState.data[props.name]],
	);

	const setFields = useCallback(
		function (formData: object) {
			let newData = props.manualData ? { ...(props.formDataField || {}), ...formData} : formData
			if(typeof formData === "function") {
				newData = formData( props.manualData ?  props.formDataField : formDataState.data)
			}
			if(props.manualData) props.setFieldFn(newData)
			else disp(formDataActions.setFields(newData));
		},
		[disp, formDataActions.setFields, formDataState.data],
	);

	function changeForm<ValueType = string>(value: ValueType, field = props.name) {
		if (field) {
			let newVal = {
				[field]: value,
			};
			if (isDotedName) {
				newVal = setProperty(formDataState.data, field || '', value);
			}
			if (props.manualData) {
				props.setFieldFn?.({
					...(props.formDataField || {}),
					...newVal,
				});
			} else {
				disp(
					formDataActions.setForm({
						...formDataState.data,
						...newVal,
					}),
				);
			}
			let validation: { isErr: boolean; errMsg: string } = { isErr: false, errMsg: '' };
			if (props.validator) {
				const [isErr, errMsg] = props.validator(value as string, props.name || '');
				validation = { isErr: !isErr as boolean, errMsg: errMsg as string };
			}
			if (props.manualData) {
				props.setValidation?.({ [props.name as any]: err });
			} else {
				disp(formDataActions.setValidation({ [field]: validation }));
			}
			if(typeof props.onChangeCallback === "function") {
				props.onChangeCallback(value, setFields)
			}
		}
	}

	function getValue() {
		let newVal = undefined;

		if (props.manualData) {
			newVal = props.formDataField[props.name as keyof typeof props.formDataField];
		} else {
			newVal = formDataState.data[props.name as keyof typeof formDataState.data];
		}
		if (isDotedName) {
			newVal = getProperty(props.manualData ? props.formDataField : formDataState.data, props.name || '');
		}
		if (props.type === 'float' || props.type === 'number' || props.type === 'integer' || props.type === 'numeric' || props.type === 'string') {
			let setup = props.setup || {};
			if (typeof setup?.masking === 'function') newVal = setup?.masking(newVal || '');
		}
		return newVal;
	}


	if (props.type === 'lookup') {
		const setup = props.setup;
		const lookupProps = props.otherProps || {};
		return (
			props.isLoading ? <SekeletonLoading /> : <LookupSelector
				isLoading={isFetching}
				filterData={setup.filterData}
				placeholder={props.title}
				columns={setup.columns}
				value={getValue()}
				error={err}
				disabled={lookupProps.disabled}
				valueField={setup.mappingField}
				onFilter={async (filter, pagination) => {
					let apiDetail = setup.apiDataDetail({ filter, pagination });
					let rsp = await getDataDropdown(apiDetail);
					if (rsp.error) console.error(rsp.error);
					if (rsp) setDataSelector(rsp.data || []);
				}}
				data={dataSelector || []}
				onChange={(value, rows) => {
					changeForm(value);
					props.setup?.onChoice?.(value, rows, changeForm, setFields);
				}}
			/>
		);
	}

	if (props.type === 'async-select') {
		const asyncSelectProps = props.otherProps || {};
		const setup = props.setup;
		let getV = function () {
			if (setup.keyValueDisplay) {
				let newVal = undefined;
				if (props.manualData) {
					newVal = props.formDataField[setup.keyValueDisplay as keyof typeof props.formDataField];
				} else {
					newVal = formDataState.data[setup.keyValueDisplay as keyof typeof formDataState.data];
				}
				if (isDotedName) {
					let arrN = (props.name || '').split('.');
					let lastDoted = arrN[arrN.length - 1];
					newVal = getProperty(
						props.manualData ? props.formDataField : formDataState.data,
						(props.name || '').replace(`.${lastDoted}`, '') || '',
					);
					return newVal[setup.keyValueDisplay as keyof typeof newVal];
				}
				return newVal;
			} else return undefined;
		};
		return (
			<div>
				<div className={classNames({'hidden' : !props.isLoading})}>
					<SekeletonLoading /> :
					</div> 
				<AsyncSelect
				{...(asyncSelectProps || {})}
				{...(setup || {})}
				valueDisplay={getV()}
				className={classNames({'hidden' : props.isLoading})}
				withCallApi={true}
				error={err}
				disabled={asyncSelectProps?.disabled}
				onChange={({ label, value }) => {
					let newVal = {
						...(setup.keyValueDisplay ? { [setup.keyValueDisplay]: label } : {}),
						[props.name || '']: value,
					};
					let arrN = (props.name || '').split('.');
					if (isDotedName) {
						let lastDoted = arrN[arrN.length - 1];
						let beforeData = getProperty<object>(
							props.manualData ? props.formDataField : formDataState.data,
							(props.name || '').replace(`.${lastDoted}`, ''),
						);
						newVal = setProperty(props.manualData ? props.formDataField : formDataState.data, (props.name || '').replace(`.${lastDoted}`, ''), {
							...beforeData,
							...(setup.keyValueDisplay ? { [setup.keyValueDisplay]: label } : {}),
							[lastDoted]: value,
						});
					}
					if (props.manualData) {
						props.setFieldFn({
							...props.formDataField,
							...newVal,
						});
					} else {
						disp(
							formDataActions.setForm({
								...formDataState.data,
								...newVal,
							}),
						);
					}
					if (typeof asyncSelectProps?.onChange === 'function') {
						asyncSelectProps?.onChange({ label, value });
					}
					let validation: { isErr: boolean; errMsg: string } = { isErr: false, errMsg: '' };
					if (props.validator) {
						const [isErr, errMsg] = props.validator(value as string, props.name || '');
						validation = { isErr: !isErr as boolean, errMsg: errMsg as string };
					}
					if (props.manualData) {
						props.setValidation?.({ [props.name as any]: err });
					} else {
						disp(formDataActions.setValidation({ [props.name as any]: validation }));
					}
					// changeForm(value);
					// if(asyncSelectProps.keyValueDisplay) {
					//    changeForm(label, asyncSelectProps.keyValueDisplay)
					// }
				}}
				asyncLoadData={async function (search: string) {
					let response: Array<{ label: string; value: string }> = [];
					if (setup.apiDataDetail) {
						try {
							let rsp = await getDataDropdown({
								url: setup?.apiDataDetail?.url || '',
								method: setup?.apiDataDetail?.method || 'GET',
								data: setup?.apiDataDetail?.body,
								queryParam: setup?.apiDataDetail?.queryParam,
							});
							let { keyValue, keyDisplay } = setup || {};
							if (rsp.error) console.error(rsp.error);
							if ((rsp.data || []).length > 0)
								response = (rsp.data || []).map((v: object) => ({
									label: v[keyDisplay as keyof typeof v],
									value: v[keyValue as keyof typeof v],
								}));
							return response;
						} catch (error: any) {
							console.error(error.toString());
						}
					}
					return response;
				}}
				value={getValue()}
			/> 
			</div>
		);
	}
	if (props.type === 'select') {
		type SelectOptions = {
			options?: Array<{ value: string; display: string }>;
		} & SelectOptionPorps;
		let selectProps: SelectOptions = props.otherProps as SelectOptions;
		const setup = props.setup;
		return (
			props.isLoading ? <SekeletonLoading /> : <Input.Select
				{...selectProps}
				value={getValue()}
				error={err}
				onChange={e => {
					changeForm((e.target as HTMLSelectElement)?.value);
					if (typeof selectProps?.onChange === 'function') selectProps?.onChange(e);
				}}
			>
				{setup.options?.map(({ value, display }, i) => (
					<option key={i} value={value}>
						{display}
					</option>
				))}
			</Input.Select>
		);
	}
	if (cekIncludeType(['float', 'number', 'integer', 'numeric', 'currency'], props.type)) {
		let inputProps = props.otherProps;
		return (
			props.isLoading ? <SekeletonLoading /> : <Input.Number
				onFocus={() => {
					setIsFocus(true);
				}}
				onBlur={() => setIsFocus(false)}
				placeholder={props.title}
				{...(inputProps || {})}
				error={err}
				value={getValue()}
				onChangeValue={val => {
					changeForm(val);
					if (typeof props.otherProps?.onChange === 'function') props.otherProps?.onChange(val);
				}}
				typeInput={props.type}
			/>
		);
	}
	if (cekIncludeType(['string', 'email', 'numeric', 'text', 'password'], props.type)) {
		let inputProps = props.otherProps;
		if(props.type === "text") return (
			props.isLoading ? <SekeletonLoading /> : <Input.TextArea
				onFocus={() => {
					setIsFocus(true);
				}}
				onBlur={() => setIsFocus(false)}
				placeholder={props.title}
				{...(inputProps || {})}
				error={err}
				value={getValue()}
				onChangeValue={e => {
					changeForm(e);
					if (typeof props.otherProps?.onChange === 'function') props.otherProps?.onChange(e);
				}}
				type="text"
			/>
		)
		return (
			props.isLoading ? <SekeletonLoading /> :<Input.Text
				onFocus={() => {
					setIsFocus(true);
				}}
				onBlur={() => setIsFocus(false)}
				placeholder={props.title}
				{...(inputProps || {})}
				error={err}
				value={getValue()}
				onChangeValue={e => {
					changeForm(e);
					if (typeof props.otherProps?.onChange === 'function') props.otherProps?.onChange(e);
				}}
				type={ props.type}
			/>
		);
	}

	if (cekIncludeType(['date', 'datetime'], props.type)) {
		let DateInputProps = props.otherProps;
		return (
			props.isLoading ? <SekeletonLoading /> : <DateInput
				onFocus={() => {
					setIsFocus(true);
				}}
				onBlur={() => setIsFocus(false)}
				{...(DateInputProps || {})}
				error={err}
				value={getValue()}
				onChange={e => {
					changeForm(e);
					if (typeof DateInputProps?.onChange === 'function') DateInputProps?.onChange(e);
				}}
			/>
		);
	}

	
	if(props.type === "checkbox") {
		let CheckboxProps = props.otherProps;
		let setup = props.setup;
		return (
			props.isLoading ? <SekeletonLoading /> : <div className='flex ml-2 space-x-1 justify-start items-center'>
				<Input.CheckBox 
					id={props.name}
					{ ...CheckboxProps}
					onChange={(e)=> changeForm((e.target as HTMLInputElement).checked ? "T" : "F")}
					checked={ getValue() === "T" }
				/> <label htmlFor={props.name}>{setup.labelName}</label>
			</div>
		)
	}
	if (props.type === 'dataSets') {
		let dataSetsProps = props.otherProps;
		let setupDatasets = props.setup;
		return (
			<GenerateFormGrid
				{...dataSetsProps}
				error={err}
				data={getValue() || []}
				onChange={data => changeForm(data)}
				fieldInput={setupDatasets.fieldInput}
				columns={setupDatasets.columns}
				readOnly={setupDatasets.readOnly}
				withAction={setupDatasets.withAction}
				customActions={setupDatasets.customActions}
				title={setupDatasets.title}
				initialForm={setupDatasets.initialFormData || {}}
			/>
		);
	}

	return <> Type form is not supported yet</>;
}
