import React from 'react';
import { BodyFormUIProps } from './default-form.interface';

export default function BodyFormUI(props: BodyFormUIProps) {
	return (
		<div className=" border border-solid  rounded-md p-[10px] mb-2">
			<div className=" w-full pt-1 mb-5">
				<span className="text-primary text-[9pt] font-bold">{props.header}</span>
				{!props.readOnly && props.withButton && (
					<div className="w-fit float-right">
						{(props.buttons || []).map((bt, bx: number) => (
							<button
								key={`btn-${bx}`}
								onClick={() => bt.onClick()}
								className=" text-primary py-0 font-bold border border-solid border-primary px-1 rounded-md hover:bg-primary-500 active:bg-transparent active:text-primary hover:text-white "
							>
								{bt.name}
							</button>
						))}
					</div>
				)}
				<hr className="mt-3 border" />
			</div>
         {props.children}
		</div>
	);
}
