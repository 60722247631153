import { PropsWithChildren, createContext, useContext, useState } from "react";
import { LoginInitialState } from "./login.interface";
import { LoginRequest } from "redux-tools/slices/auth/auth.interface";
import useFormData from "redux-tools/slices/form-data/form-data.hook";

let initialState: LoginInitialState = {
   loginAction: {},
   loginState: {},
   formData: {
      formState: { email: "", password: "" },
      formActions: {
         setField(fieldName, value) {},
         setFields(formData) {},
         resetForm() {},
      },
      formValidation: {},
   },
};
const context = createContext(initialState);

export function LoginProvider(props: PropsWithChildren) {
   const formData = useFormData<LoginRequest>({
      email: "",
      password: "",
   });
   const [form, setForm] = useState<LoginRequest>({
      email: "",
      password: "",
   });

   return (
      <context.Provider
         value={{
            loginAction: {},
            loginState: {},
            formData
         }}
      >
         {props.children}
      </context.Provider>
   );
}

export default function useLogin() {
   return useContext(context);
}
