import {
   Action,
   AnyAction,
   createSlice,
   PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { AuthState } from "./auth.interface";
import { fetchLogin, fetchLogout, fetchMe } from "./auth.action";

const initialState: AuthState = {
   isAuthenticated: false,
   isInitialized: false,
   isLoading: false,
   userLogin: {}
}

interface RejectedAction extends Action {
   error: Error;
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
   return action.type.endsWith("rejected");
}

const formDataSlices = createSlice({
   name: "auth",
   initialState,
   reducers: {
      setLoading(state: AuthState, action: PayloadAction<AuthState["isLoading"]>){
         return {
            ...state,
            isLoading: action.payload
         }
      },
      setIsauth(state: AuthState, action: PayloadAction<AuthState["isAuthenticated"]>){
         return {
            ...state,
            isAuthenticated: action.payload
         }
      },
      setIsInit(state: AuthState, action: PayloadAction<AuthState["isInitialized"]>){
         return {
            ...state,
            isInitialized: action.payload
         }
      }
   },
   extraReducers(builder) {
      builder.addCase(
         fetchLogin.fulfilled, (state, action) => {
            let {payload} = action;
            if(payload.code ==="00"){
               state.isAuthenticated = true;
               state.userLogin  = payload?.data?.user;
            }
            state.isLoading = false;
         }
      );
      builder.addCase(
         fetchLogin.pending, (state, action) => {
            state.isLoading= true;
         }
      );
      builder.addCase(
         fetchLogout.pending,  (state, action) => {
            state.isLoading= true;
         }
      );
      builder.addCase(
         fetchLogout.fulfilled,  (state, action) => {
            state.isLoading= false;
            state.isAuthenticated= false;
            state.userLogin = {}
         }
      );
      builder.addCase(
         fetchMe.pending,  (state, action) => {
            state.isLoading= true;
         }
      );
      builder.addCase(
         fetchMe.fulfilled,  (state, action) => {
            let {payload} = action;
            if(payload){
               if(payload.code ==="00"){
                  state.isAuthenticated = true;
                  state.userLogin  = payload?.data;
               }
            }
            state.isLoading = false;
         }
      );
   }
});

export const authActions = formDataSlices.actions;
export const authSelector = (state: RootState): AuthState => state.authState;
export default formDataSlices.reducer;