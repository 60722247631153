import React from "react";
import {
   Route,
   RouteObject,
   RouterProvider,
   Routes,
   createBrowserRouter,
   useRoutes,
} from "react-router-dom";
import * as allPage from "../pages";
import * as allPublicPage from "../pages/public";
import { useSelector } from "react-redux";
import { authSelector } from "redux-tools/slices/auth/auth.slices";
import { appConfig } from "./app.config";

const page = (pageName: keyof typeof allPage) =>
   React.lazy(() =>
      import("../pages").then((value) => ({
         default: value[pageName as keyof typeof value],
      }))
   );

   const publicPage = (pageName: keyof typeof allPublicPage) =>
   React.lazy(() =>
      import("pages/public").then((value) =>{
         return ({
            default: value[pageName as keyof typeof value],
         })
      } 
      )
   );

const adminRoute: Array<RouteObject & {roleAccess?: Array<number | string>}> = [
   {
      path: "/",
      Component: page("DashboardPage"),
      index: true,
      roleAccess: [1],
   },
   {
      path: "/laporan/laporan-kas",
      index: true,
      Component: page("LaporanKasPage"),
   },
   {
      path: "/laporan/laporan-pemasukan",
      index: true,
      Component: page("LaporanPemasukanPage"),
   },
   {
      path: "/laporan/laporan-pengeluaran",
      index: true,
      Component: page("LaporanPengeluaranPage"),
   },
   {
      path: "/content/galery",
      index: true,
      Component: page("GaleryPage"),
   },

   {
      path: "/content/dokumentasi",
      index: true,
      Component: page("DokumentasiPage"),
   },

   {
      path: "/content/informasi-masjid",
      index: true,
      Component: page("InformasiMasjidPage"),
   },
   {
      path: "/approval/need-approval",
      index: true,
      Component: page("NeedApprovalPage"),
   },
   {
      path: "/approval/hostory",
      index: true,
      Component: page("ApprovalHistoryPage"),
   },
   {
      path: "/users-management/list",
      index: true,
      Component: page("UserListPage"),
   },
   {
      path: "/users-management/roles",
      index: true,
      Component: page("RolesPage"),
   },
];
const adminPublicRoute = [
   {
      path: "/login",
      Component: page("LoginPage"),
   },
];

const publicRoute = [
   {
      path: "/",
      Component: publicPage("Homepage")
   },
   {
      path: "/laporan",
      Component: publicPage("LaporanPage"),
   },
   {
      path: "/about",
      Component: publicPage("AboutPage"),
   },
   {
      path: "/gallery",
      Component: publicPage("GalleryPage"),
   },
   {
      path: "/shodaqoh",
      Component: publicPage("ShodaqohPage"),
   },

]

export const routes = createBrowserRouter([
   {
      path: "/",
      errorElement:  <allPage.ErrorPage />,
      Component: appConfig.APP_TYPE === "ADMIN" ? page("MainAdminPage") : page("MainPublicPage"),
      children: appConfig.APP_TYPE === "ADMIN" ? adminRoute : publicRoute,
   },
   ...( appConfig.APP_TYPE === "ADMIN" ? adminPublicRoute : []),
]);

export function RouteRender() {
   const authState = useSelector(authSelector)
   return (
      <>
         {/* <Routes>
            <Route path="/" Component={page("MainAdminPage")} errorElement={ <allPage.ErrorPage />}>
               {
                  adminRoute.map((pr, i) => (
                     <Route path={pr.path} Component={pr.Component} key={i} />
                  ))
               }
            </Route>
            {publicRoute.map((pr, i) => (
               <Route path={pr.path} Component={pr.Component} key={i} />
            ))}
         </Routes> */}
         <RouterProvider
            // fallbackElement={<LoadingPage isLoading={true} />}
            router={routes}
         ></RouterProvider>
      </>
   );
}
