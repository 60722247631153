
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProperty } from "../../../_module/helper/objByString";
import { FormDataState, FormDataHook } from "./form-data.interface";
import { formDataActions, formDataSelector } from "./form-data.slices";
import { useRoutes, useLocation } from "react-router";

export default function useFormData<T>(initialState: T): FormDataHook<T> {
   const [initialized, setInitialized] = useState<boolean>(false);
   const formDataState: FormDataState<T> = useSelector(formDataSelector);
   const disp = useDispatch();
   const router = useLocation();
   const getPath: string = useMemo(
      function (): string {
         return router?.pathname?.replace("#", "");
      }, [router]
   )
   const resetForm = useCallback(
      function () {
         disp(formDataActions.setForm<T>(initialState));
      },
      [initialState, disp, formDataActions.setField]
   );
   const resetValidation = useCallback(
      function () {
         disp(formDataActions.resetValidation());
      },
      [initialState, disp, formDataActions.setField]
   );
   
   function resetAll(){
      resetForm();
      resetValidation();
   }
   const setField = useCallback(
      function <ValueType>(fieldName: string, value: ValueType) {
         let newData : T  = {
            ...formDataState.data, 
            [fieldName] : value
         }
         if( fieldName.split(".").length>1 ){
            newData = setProperty<ValueType, T >(formDataState.data, fieldName, value )
         }
         disp(formDataActions.setForm<T>(newData))
      },
      [disp, formDataState.data,formDataActions.setForm]
   );

   const setFields = // useCallback(
      function( formData: T | object  ) {
         let newData : T = formData as T;
         if( typeof formData === "function") {
            newData = (formData as (data: T) => T)(formDataState.data)
         }
         disp(formDataActions.setFields<T>(({ ...formDataState.data, ...newData}) as T))
      } //,  [disp, formDataActions.setFields]
   // )

   return {
      formState: formDataState.data,
      formValidation: formDataState.validation,
      formActions: {
         setField,
         setFields,
         resetForm,
      },
   };
}
