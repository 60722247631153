import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Swal from "sweetalert2";
import { axiosBaseQuery } from "../../../_module/helper/api";
import { appConfig } from "config/app.config";

const publicGallery = createApi({
   baseQuery: axiosBaseQuery({
      baseUrl: `${appConfig.BASE_URL_API}/v1/public/gallery`,
   }),
   reducerPath: "galleryPublic",
   tagTypes: ["/gallery"],
   endpoints: (builder) => ({
      getAllGalleryPublic: builder.query({
         query:({
            page= 1
         }: { page: number, type?:string }) => ({
            url:"/all",
            queryParam: {page},
            method: "GET",
            data: {},
            
         }),
      }),
   }),
});

export const {
   useGetAllGalleryPublicQuery,
   useLazyGetAllGalleryPublicQuery,
} = publicGallery;

export const { getAllGalleryPublic } = publicGallery.endpoints;
export default publicGallery;
