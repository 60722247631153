import { Button } from "../../components";
import addNotification from "react-push-notification";
import { Notifications } from "react-push-notification";
import { appConfig } from "../../config/app.config";

export default function TestPage() {
   return (
      <>
         <Notifications />
         <Button
            onClick={() => {
               new Notification("Test")
               
               // new Notification.call({
               //    body: "TEST",
               //    data: undefined,
               //    dir: "auto",
               //    icon: "",
               //    lang: "",
               //    onclick: null,
               //    onclose: null,
               //    onerror: null,
               //    onshow: null,
               //    tag: "",
               //    title: "",
               //    close: function (): void {
               //       throw new Error("Function not implemented.");
               //    },
               //    addEventListener: function <K extends keyof NotificationEventMap>(type: K, listener: (this: Notification, ev: NotificationEventMap[K]) => any, options?: boolean | AddEventListenerOptions | undefined): void {
               //       throw new Error("Function not implemented.");
               //    },
               //    removeEventListener: function <K extends keyof NotificationEventMap>(type: K, listener: (this: Notification, ev: NotificationEventMap[K]) => any, options?: boolean | EventListenerOptions | undefined): void {
               //       throw new Error("Function not implemented.");
               //    },
               //    dispatchEvent: function (event: Event): boolean {
               //       throw new Error("Function not implemented.");
               //    }
               // }, "testing");
            }}
         >
            Test Notification {appConfig.BASE_URL_API}
         </Button>
      </>
   );
}
