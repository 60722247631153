import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { Axios, AxiosError, AxiosResponse } from "axios";
import { appConfig } from "config/app.config";
import { LoginRequest } from "./auth.interface";
import Swal from "sweetalert2";
import axiosWithAuth from "_module/helper/axiosWithAuth";

export const fetchLogin = createAsyncThunk(
   "auth/login",
   async function ({
      data,
      setToken = () => null,
   }: {
      data: LoginRequest;
      setToken(token: string): any;
   }) {
      try {
         const resp = await axios({
            baseURL: appConfig.BASE_URL_API,
            url: "/v1/auth/access-token",
            data,
            method: "POST",
         });
         if (resp.status === 200) {
            const { data } = resp;
            if(data.code === "00") {
               setToken(data.data?.access_token);
               return resp.data;
            }
            else throw "Username or Password Salah";
         }
         if (resp.status === 401) {
            throw "Username or Password Salah";
         } else {
            Swal.fire({
               title: "Failed",
               icon: "info",
               text: resp.data?.message,
            });
            throw {
               code: resp.status,
               message: resp.statusText,
            };
         }
      } catch (error: any) {
         let err: AxiosError = error;
         console.log({ err });
         if (err.response?.status === 401) {
            Swal.fire({
               title: "Failed",
               icon: "info",
               text: "Username or Password salah",
            });
         } else {
            Swal.fire({
               title: "Failed",
               icon: "error",
               text: error.toString(),
            });
         }
         return {
            code: 500,
            message: error.toString(),
         };
      }
   }
);

export const fetchLogout = createAsyncThunk(
   "auth/logout",
   async function (callBack : ()=>any) {
      try {
         let reqAxios = axiosWithAuth()
         const resp = await reqAxios({
            baseURL: appConfig.BASE_URL_API,
            url: "/v1/auth/logout",
            method: "POST",
         });
         if (resp.status === 200) {
            const { data } = resp;
            if (data.code === "00") {
               return resp.data;
            } else throw "Failed to logout";
         }
      } catch (error: any) {
         let err: AxiosError = error;
      }
      callBack()
   }
);

export const fetchMe = createAsyncThunk(
   "auth/me",
   async function (callBack : (resp:any)=>any) {
      try {
         let reqAxios = axiosWithAuth()
         const resp = await reqAxios({
            baseURL: appConfig.BASE_URL_API,
            url: "/v1/auth/me",
            method: "GET",
         });
         if (resp.status === 200) {
            const { data } = resp;
            if (data.code === "00") {
               callBack(resp);
               return resp.data;
            } else throw "Failed to logout";
         }
      } catch (error: any) {
         let err: AxiosError = error;
         let {response} = err;
         callBack(response);
         if(response?.status === 401 || response?.status === 403) {

         }
         return response;
      }
   }
);