import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DateInputProps } from "./date-input.interface";
import classNames from "classnames";

const DateInput = ({
   value = "",
   className = "",
   onChange = () => null,
   disabled = false,
   readOnly = false,
   error,
}: DateInputProps) => {
   function validDate(d: string | Date ) {
      if(typeof d === "string"){
         var timestamp = Date.parse(d);
         return !isNaN(timestamp);
      }else return true
   }
   return (
      <>
         <div className="text-[8pt]">
            <DatePicker
               className={
                  classNames(
                     ` 
                     appearance-none
                     border rounded w-full py-2 px-3 
                     text-gray-700 leading-tight
                     focus:outline-primary-500 focus:shadow-outline] ${className}
                     focus:invalid:border-danger
                  `,
                     {
                        " border-[#b3b3b3]": !error?.isErr,
                        "border-danger": error?.isErr,
                     }
                  )
               }
               dateFormat="dd/MM/yyyy"
               onKeyDown={(e: any) => {
                  // console.log(e.keyCode)
                  let val = (e.target as HTMLInputElement).value.replace(
                     /[^0-9 \-]/g,
                     ""
                  );
                  if (e.keyCode !== 8 && e.keyCode !== 46) {
                     val = (e.target as HTMLInputElement).value.replace(
                        /[^0-9 / \-]/g,
                        ""
                     );

                     if (val.length <= 10) {
                        if (val.length === 2 || val.length === 5) {
                           val = val + "/";
                           // console.log(val)
                           // console.log(val.length)
                           (e.target as HTMLInputElement).value = val;
                        }
                     }
                  }
               }}
               disabled={disabled}
               readOnly={readOnly}
               showYearDropdown={true}
               isClearable={true}
               placeholderText={"DD/MM/YYYY"}
               value={
                  (value !== undefined && value !== null
                     && validDate(value)
                 ) && value ? moment(new Date(value)).format("DD/MM/YYYY") : ""
               }
               onChange={(e : any) => {
                  //validDate(e) ?
                  onChange?.(moment(e).format("YYYY-MM-DD")); // : onChange?.("")
               }}
            />
         </div>
         {error?.isErr && <span className="text-danger">{error.errMsg}</span>}
      </>
      //   <DatePicker
      //       className={className}
      //       selected={
      //           (value !== undefined && value !== null
      //               && validDate(value)
      //           ) && value ? new Date(value) : ""
      //       }
      //       type="date"
      //       dateFormat="dd/MM/yyyy"
      //       disabledKeyboardNavigation
      //       onKeyDown={e => {

      //           // console.log(e.keyCode)
      //           let val = e.target.value.replace(/[^0-9 \-]/g, '')
      //           if (e.keyCode !== 8 && e.keyCode !== 46) {
      //               val = e.target.value.replace(/[^0-9 / \-]/g, '')

      //               if(val.length <= 10){
      //                   if (val.length === 2 || val.length === 5) {
      //                       val = val + "/"
      //                       // console.log(val)
      //                       // console.log(val.length)
      //                       e.target.value = val

      //                   }
      //               }
      //           }
      //       }}

      //       value={
      //           (value !== undefined && value !== null
      //               && validDate(value)
      //           ) && value ? new Date(value) : ""
      //       }

      //       // onKeyUp={() =>null}
      //       onChange={(e) => {
      //           //validDate(e) ?
      //           onChange?.(moment(e).format("YYYY-MM-DD")) // : onChange?.("")
      //       }}
      //       disabled={disabled}
      //       readOnly={readOnly}
      //       placeholderText={"DD/MM/YYYY"}
      //   // onBlur={
      //   //     (e) => {
      //   //         if (onFocusOut !== null && onFocusOut !== undefined) {
      //   //             onFocusOut?.(e.target.value)
      //   //         }
      //   //     }
      //   // }
      //   />
   );
};

export default DateInput;
