import { sidemenuPropsType } from "components/_layout/layout.interface"


const listMenu :  Array<sidemenuPropsType> = [
   {
      id: "c",
      name: "Dashboard",
      url: "/",
      hasChild: false,
      childs: [],
      icon: "",
      iconType: "css"
   },
   {
      name:"Laporan",
      id: "c",
      url: "/laporan",
      hasChild: true,
      childs: [
         {
            id: "c",
            name: "Laporan Kas",
            url: "/laporan/laporan-kas",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         },
         
         {
            id: "c",
            name: "Laporan Pemasukan",
            url: "/laporan/laporan-pemasukan",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         },
         
         {
            id: "c",
            name: "Laporan Pengeluaran",
            url: "/laporan/laporan-pengeluaran",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         },
     
      ],
      icon: "",
      iconType: "css"
   },
   {
      name:"Content",
      id: "c",
      url: "/content",
      hasChild: true,
      childs: [
         {
            id: "c",
            name: "Galery",
            url: "/content/galery",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         },
         {
            id: "c",
            name: "Informasi Masjid",
            url: "/content/informasi-masjid",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         }
      ],
      icon: "",
      iconType: "css"
   },
   
   {
      name:"Approval",
      id: "c",
      url: "/approval",
      hasChild: true,
      childs: [
         {
            id: "c",
            name: "Need Approval",
            url: "/approval/list",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         },
         
         {
            id: "c",
            name: "History",
            url: "/approval/history",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         }
      ],
      icon: "",
      iconType: "css"
   },
   {
      name:"Users Management",
      id: "c",
      url: "/users-management",
      hasChild: true,
      childs: [
         {
            id: "c",
            name: "User List",
            url: "/users-management/list",
            hasChild: false,
            childs: [],
            icon: "",
            iconType: "css"
         }
      ],
      icon: "",
      iconType: "css"
   }
]

export default listMenu