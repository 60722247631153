import { ApprovalHistoryProvider } from "./approval-history.provider"

const LPPage = () => {

   return (
      <>
         <div className="">
            <h2>Laporan Kas</h2>
         </div>
      </>
   )
}


export default (props: any) => <ApprovalHistoryProvider>
   <LPPage { ...props} />
</ApprovalHistoryProvider>

