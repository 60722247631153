
type SkeletonLoadingPropsType = {
   height?: number | string;
}

export default function SkeletonLoading (props:SkeletonLoadingPropsType) {
	return (
		<>
			<div className=" animate-pulse" style={{height: (props.height || 25)}}>
				<div className="h-full bg-[#D9D9D9] rounded-md">
					<div className="h-full bg-[#969696] rounded-md"></div>
				</div>
			</div>
		</>
	);
}
