
import classNames from 'classnames';
import { layoutStateInterface, sidemenuPropsType } from './layout.interface';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import * as Fi from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { layoutSelector, layoutActions } from './layout.slices';
import {  useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
export default function Sidemenu(
	props: sidemenuPropsType & { level?: number; hasChild?: boolean; idMenus: layoutStateInterface['menuActiveDetail']; idMenu: string },
): JSX.Element {
	let router = useNavigate()
	let location = useLocation()
	let level: number = props.level || 1;
	const layoutState: layoutStateInterface = useSelector(layoutSelector);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const disp = useDispatch();
	const reffDrop = useRef<HTMLDivElement>(null);
	let selfIdMenuActive = {
		id: props.idMenu,
		title: props.name,
		url: props.url,
	};

	const getPath: string = useMemo(
		function (): string {
			return location.pathname?.replace('#', '');
		},
		[router],
	);
	const getActiveMenu: boolean = useMemo(
		function (): boolean {
			return layoutState.menuActiveDetail.some( v => v.id === props.idMenu)
			// return (
			// 	layoutState.menuActiveDetail.some(v => v.id === props.idMenu) ||
			// 	(props.url === '/'
			// 		? getPath === '/'
			// 		: getPath.indexOf(props.url) > -1 || props.childs.some(c => (c.url === '/' ? getPath === '/' : getPath.indexOf(c.url) > -1)))
			// );
		},
		[props.idMenu, layoutState.menuActiveDetail],
	);
	const toggleChild = useCallback(() => {
		if (props.hasChild && getActiveMenu) disp(layoutActions.setMenuActive(props.idMenus));
		else disp(layoutActions.setMenuActive([...props.idMenus, selfIdMenuActive]));
	}, [props.idMenus, props.hasChild, getActiveMenu, layoutActions.setMenuActive, selfIdMenuActive]);

	useEffect(
		function () {
			if (
				props.url === '/'
					? getPath === '/' :
					!props.hasChild ?
					getPath === props.url
					: getPath.indexOf(props.url) > -1 || props.childs.some(c => (c.url === '/' ? getPath === '/' : getPath.indexOf(c.url) > -1))
			)
				disp(layoutActions.setMenuActive([...props.idMenus, selfIdMenuActive]));
		},
		[],
	);
	return (
		<div ref={reffDrop}>
			<dl
				id="menu"
				className={classNames('w-full mt-0 text-white', {
					'bg-sidebar-700': level > 1,
				})}
			>
				<dt
					id="menu"
					style={{
						paddingLeft: (level - 1) * 15,
					}}
					className={classNames('py-2 duration-700 ease-in-out transition-all ', {
						// "bg-sidebar-700": isOpen,
						'bg-white text-sidebar-500': getActiveMenu && level === 1,
						' bg-sidebar-600 text-white': getActiveMenu && level > 1 && props.hasChild,
						'hover:bg-sidebar-700': !getActiveMenu && level === 1,
						// "border-b border-b-solid border-b-white ": isOpen && level > 1 && props.hasChild,
					})}
				>
					{props.hasChild ? (
						<div
							onClick={toggleChild}
							id="menu"
							className={classNames('  group flex relative break-words content-center w-[100%]  items-center  cursor-pointer pr-4 pl-5', {})}
						>
							<span id="menu" className="group-hover:font-bold ml-2 mr-5 text-lg absolute left-0">
								{props.icon}
							</span>
							<span
								id="menu"
								className={classNames(' group-hover:font-bold text-xs px-1 rounded-md  w-full mr-2', {
									' font-extrabold': getActiveMenu,
								})}
							>
								{props.name}
							</span>
							{props.hasChild && (
								<span
									id="menu"
									className={classNames(' absolute right-1 mt-1  duration-300 ease-in-out transition-all transform', {
										'rotate-90': getActiveMenu,
									})}
								>
									<Fi.FiChevronRight />
								</span>
							)}
						</div>
					) : (
						<Link
							to={props.hasChild ? '#' : props.url}
							onClick={toggleChild}
							id="menu"
							className={classNames('  group flex relative break-words content-center w-[100%]  items-center  cursor-pointer pr-4 pl-5', {})}
						>
							<span id="menu" className="group-hover:font-bold ml-2 mr-5 text-lg absolute left-0">
								{props.icon}
							</span>
							<span
								id="menu"
								className={classNames(' group-hover:font-bold text-xs px-1 rounded-md  w-full mr-2', {
									' font-extrabold': getActiveMenu,
								})}
							>
								{props.name}
							</span>
						</Link>
					)}
				</dt>
			</dl>
			{getActiveMenu &&
				props.hasChild &&
				props.childs.map((c, i) => (
					<Sidemenu
						idMenus={[...props.idMenus, selfIdMenuActive]}
						idMenu={`menu-${props.idMenu || ''}-${level}${i}-${i}`}
						{...c}
						key={i}
						hasChild={c.childs.length > 0}
						level={level + 1}
					/>
				))}
		</div>
	);
}
