import { Button, Card, FileInput, Loader, LoadingPage, Modal, TitlePage } from "components";
import { GaleryProvider } from "./galery.provider";
import * as Fa from "react-icons/fa";
import foto1 from "./dummyImage/1.jpg";
import foto2 from "./dummyImage/2.jpg";
import foto3 from "./dummyImage/3.jpg";
import foto4 from "./dummyImage/4.jpg";
import foto5 from "./dummyImage/5.jpg";
import { useCallback, useState } from "react";

let dummyImages = [foto1, foto2, foto3, foto4, foto5, foto1, foto2, foto3, foto4, foto5];
const LPPage = () => {
   const [modalView, setModalView] = useState({
      key:0,
      show: false,
      imageUrl: "" 
   })
   const [loadingImage, setLoadingImage] = useState<boolean>(true)

   const openImage = useCallback(function(imageUrl:string, key:number){
      setLoadingImage(true);

      setModalView({
         key, show:true, imageUrl
      })
      setTimeout(function(){
         setLoadingImage(false);

      },500)
   },[modalView])

   const closeImage = useCallback(function(){
      setModalView({
         key:0,
         show: false,
         imageUrl: ""
      })
   }, [setModalView])
   return (
      <>
         <TitlePage title={"Galery"} subtitle={""} />
         <div className="mt-10">
            <div className="mb-5">
               <Card className="min-h-[0px]">
                  <Card.Body className="min-h-0">
                     <div>
                        <label>Upload Foto</label>
                        <div  className="flex space-x-2" >
                        <FileInput  accept="image/png, image/gif, image/jpeg" /> <Button>Upload</Button>

                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            <div className="grid gap-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
               {dummyImages.map((im, i) => (
                  <div key={i} className="group relative">
                     <div className="hidden group-hover:flex absolute text-xl h-full w-full top-0 right-0 bg-white/50 items-center justify-center">
                        <div className=" flex">
                           <button onClick={()=> {
                              openImage(im, i)
                           }} className="mx-auto center flex text-primary items-center space-x-2 rounded-md py-1 px-2 hover:bg-white active:bg-slate-400">
                              <Fa.FaEye className="mx-auto" />
                           </button>
                           <button className="mx-auto center text-red-500 flex items-center space-x-2 rounded-md py-1 px-2 hover:bg-white active:bg-slate-400">
                              <Fa.FaTrash className="mx-auto" />
                           </button>
                        </div>
                     </div>
                     <img
                        src={im}
                        className="h-auto max-w-full aspect-[16/9] rounded-md"
                     />
                  </div>
               ))}
              
            </div>
            <div className="mt-5 w-full text-center">
               <Button >
                  <div className="flex justify-center items-center space-x-2">
                     <Fa.FaSyncAlt className="mr-2" />
                     Load More
                  </div>
               </Button>
            </div>
         </div>
         <Modal showModal={modalView.show} onHide={closeImage} className="border-0 border-none bg-white/0 shadow-none w-full">
            <div className="w-full h-full bg-white/0">
               <div className="absolute right-[2%] top-[-5%]">
                  <button onClick={closeImage} className="md:text-lg text-sm">   <Fa.FaTimes size={20}  /> </button>
               </div>
               {
                  loadingImage ? <div className="w-fit mx-auto"> <Loader size={50} /> </div> : (
                     <img
                        src={modalView.imageUrl}
                        className="h-auto w-[90%] mx-auto bg-none rounded-md shadow-lg"
                     />
                  )
               }
            </div>
         </Modal>
      </>
   );
};

export default (props: any) => (
   <GaleryProvider>
      <LPPage {...props} />
   </GaleryProvider>
);
