import { MainLayout } from "components";
import { Outlet, useLocation, useNavigate } from "react-router";
import useAuth from "redux-tools/slices/auth/auth.hook";
import { Navigate } from 'react-router-dom'
import { useEffect } from "react";

export default function MainAdmin(){
   const location = useLocation()
   const { 
      authState,
      authAction
    } = useAuth();
   //  if(!authState.isInitialized) return <></>
   useEffect(function(){
      if(authState.isAuthenticated) authAction.getMe()
   },[authState.isAuthenticated,  authState.isInitialized ])
   if( authState.isInitialized && !authState.isAuthenticated) return <Navigate to="/login" replace />;
  
   return <MainLayout>
      <Outlet />
   </MainLayout>
}