import { PropsWithChildren, createContext, useContext } from "react";

const context = createContext({})

export function RolesProvider(props: PropsWithChildren) {

   return <context.Provider value={{}}>
      {props.children}
   </context.Provider>
}


export default function useRoles(){ return useContext(context) }