import { PropsWithChildren, createContext, useContext } from "react";

const context = createContext({})

export function UserListProvider(props: PropsWithChildren) {

   return <context.Provider value={{}}>
      {props.children}
   </context.Provider>
}


export default function useUserList(){ return useContext(context) }