import { Card, TableGrid, TitlePage } from "components"
import { LaporanPemasukanProvider } from "./laporan-pengeluaran.provider"

const LPPage = () => {

   return (
      <>
      <TitlePage title={"Laporan"} subtitle={"Laporan Pemasukan"} />
      <div className="mt-10">
         <div className="mb-3">
            <Card className="min-h-[0px]">
               <Card.Body className="min-h-0">
                  <div className="grid grid-cols-1 gap-y-2">
                     <div>
                        Bulan September
                     </div>
                     <div className=" border p-2 rounded-md"> 
                        Total Pemasukan :
                     </div>
                  </div>
               </Card.Body>
            </Card>
         </div>
         <div>
            <Card>
               <Card.Body>
                  <TableGrid 
                     columns={[
                        { field:"tgl_trx", title:"Tanggal" },
                        { field:"tgl_trx", title:"Deskripsi" },
                        { field:"nominal", title:"Nominal" },
                     ]} 
                     isLoading={false} 
                     data={[]} 
                     withAction={false} 
                     iterationNumber={false} 
                  />
               </Card.Body>
            </Card>
         </div>
      </div>
   </>
   )
}


export default (props: any) => <LaporanPemasukanProvider>
   <LPPage { ...props} />
</LaporanPemasukanProvider>

