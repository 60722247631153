import { useDispatch, useSelector } from "react-redux";
import { AuthHook, AuthState, LoginRequest } from "./auth.interface";
import { authActions, authSelector } from "./auth.slices";
import { fetchLogin, fetchLogout, fetchMe } from "./auth.action";
import { AppDispatch } from "redux-tools/store";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

export default function useAuth(): AuthHook {
   const disp: AppDispatch = useDispatch();
   const authState = useSelector(authSelector)
   const [cookies, setCookies, removeCookies] = useCookies(['access_token'])
   const setAccessToken = (token:string) =>{ setCookies("access_token", token)}
   const doLogin = (body: LoginRequest) => {
      disp(fetchLogin({
         data:body,
         setToken(token) {
            setAccessToken(token)
         },
      } ))
   }
   const doLogout = () => {
      disp(fetchLogout(()=>removeCookies("access_token")))
   }
   const getMe = () => {
      disp(fetchMe((function(resp){
        
         if(resp?.status === 401 || resp?.status === 403) {
            doLogout()
         }
      })))
   }
   return {
      authState,
      authAction: {
         doLogin,
         doLogout,
         getMe
      }
   };
}