import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import { useGetAllGalleryPublicQuery, useLazyGetAllGalleryPublicQuery } from "redux-tools/services/gallery/gallery-public.service";
import Swal from "sweetalert2";

const context = createContext({} as any)

export function GaleryProvider(props: PropsWithChildren) {

   const [page, setPage] = useState(1);
   const [loading, setLoading] = useState(false);
   const [dataList, setDataList] = useState([]);
   const [getList, statusGetList  ] = useLazyGetAllGalleryPublicQuery()
   console.log("ssss")
   const getDataList = useCallback(
      async ()=> {
         try {
            setLoading(true)
            const payload: any = await getList({ page})
            if (payload?.error) Swal.fire('Info', payload.message, 'warning');
            else {
               if (payload.data) {
                     console.log(payload.data)
                     // formData.formActions.setFields(payload.data?.flow_data);
                  } else Swal.fire('Info', payload.message, 'warning');
               }
         } catch (error: any) {
            Swal.fire('Error', error.toSrting(), 'error');
         }
         setLoading(false)
      }, [page]
   )

   useEffect(function(){
      console.log("geting")
      getDataList()
   },[])
   
   return <context.Provider value={{
      action: { 
            getDataList 
         },
      state: { 
         loading
      }
   }}>
      {props.children}
   </context.Provider>
}


export default function useGalery(){ return useContext(context) }