type propsType = {
   title: string;
   subtitle: string;
};
export default function PageTitle ({ title, subtitle }: propsType): JSX.Element {
   return (
      <>
         <div className="my-3">
            <h2 className="text-xl font-bold">{title}</h2>
            <span className="text-sm">{subtitle}</span>
         </div>
         <hr />
      </>
   );
}
