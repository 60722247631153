import { appConfig } from "config/app.config"
import { DashboardProvider } from "./dashboard.provider"

const LPPage = () => {

   return (
      <>
         <div className="">
            <h2>{
               appConfig.APP_TYPE
               }</h2>
         </div>
      </>
   )
}


export default (props: any) => <DashboardProvider>
   <LPPage { ...props} />
</DashboardProvider>

