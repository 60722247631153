import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import dropdown from "./dropdown/dropdown.service";
import thunkMiddleware from "redux-thunk";
import publicGallery from "./gallery/gallery-public.service";
export default (getDefaultMiddleware: CurriedGetDefaultMiddleware) => 
   getDefaultMiddleware({
      serializableCheck:false
   })
   .concat(
      
      dropdown.middleware,
      publicGallery.middleware,
      thunkMiddleware
   )