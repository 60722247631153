import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import { RootState } from "../../redux-tools/store";
import { layoutStateInterface, sidemenuPropsType } from './layout.interface';
import { layoutSelector } from './layout.slices';
import logo from './img/logo-putih.png';
import Sidemenu from './layout.sidemenu';

import listMenu from '../../config/sidebar.config';

export default function Sidebar<FC>() {
	const layoutState: layoutStateInterface = useSelector(layoutSelector);
	const disp = useDispatch();
	const [hidingMobile, setHidingMobile] = useState<boolean>(false);

	useEffect(
		function () {
			let a: any;
			if (layoutState.sidebarOpen) {
				a = setTimeout(() => setHidingMobile(true), 500);
			} else setHidingMobile(false);
			return () => clearTimeout(a);
		},
		[layoutState.sidebarOpen],
	);

	// ${ layoutState.sidebarOpen ? "-translate-x-full" : "translate-x-full" }
	let cn = classNames({
		'md:translate-x-0': layoutState.sidebarOpen,
		'md:-translate-x-full': !layoutState.sidebarOpen,
		'translate-x-0': !layoutState.sidebarOpen,
		'-translate-x-full': layoutState.sidebarOpen,
		'max-md:z-50': !hidingMobile,
	});

	return (
		<div
			id="sidebar"
			className={`fixed shadow-md ${cn}  bg-sidebar top-0 w-[270px] h-screen py-1 px-3 overflow-y-auto border-r ease-in-out duration-300`}
		>
			<div id="sidebar" className=" text-center font-mono  mb-1">
				<h1 id="sidebar" className="text-2xl font-semibold text-white py-3">
					<img src={logo} width={160} height={100} alt="logo" className="mx-auto" />
				</h1>
			</div>
			<div className=" text-sm max-w-full mt-5 overflow-hidden bg-sidebar-600 rounded-md">
				{listMenu.map((m: sidemenuPropsType, i: number) => (
					<Sidemenu idMenus={[]} {...m} key={i} hasChild={m.hasChild} idMenu={`menu-${i}`} />
				))}
			</div>
		</div>
	);
}
