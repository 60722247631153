import { InformasiMasjidProvider } from "./informasi-masjid.provider"

const LPPage = () => {

   return (
      <>
         <div className="">
            <h2>Laporan Kas</h2>
         </div>
      </>
   )
}


export default (props: any) => <InformasiMasjidProvider>
   <LPPage { ...props} />
</InformasiMasjidProvider>

