import { PropsWithChildren } from "react";
import PublicMenu from "./public-layout.menu";

export default function (props: PropsWithChildren) {
   return (
      <>
         <div className=" min-h-full max-h-full w-height overflow-hidden">
            
               <PublicMenu />
            <div className={`
                  sm:min-h-[calc(100vh_-_50px)] pb-[100px] 
                  md:pb-[50px] pt-[60px] px-3 w-full 
                  mnlaptop:w-[70%] lg:max-mxmobile::w-[90%] 
                  lg:mx-auto m-0
            `}>
               {props.children}
            </div>
         </div>
      </>
   );
}
