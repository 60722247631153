import {
   Action,
   AnyAction,
   createSlice,
   PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { FormDataState } from "./form-data.interface";


const initialState: FormDataState = {
   data: {},
   validation: {}
}

interface RejectedAction extends Action {
   error: Error;
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
   return action.type.endsWith("rejected");
}

const formDataSlices = createSlice({
   name: "formData",
   initialState,
   reducers: {
      setForm<T>( state: FormDataState<T>, action: PayloadAction<FormDataState<T>["data"]> ){
         return {
            ...state,
            data: action.payload
         }
      },
      setField<T = object>(state: FormDataState<T>, action: PayloadAction<{ fieldName: keyof FormDataState<T>["data"]; value: any; }>){
         const {payload: { fieldName, value } } = action;
         return {
            ...state,
            data: {
               ...state.data,
               [fieldName] : value
            }
         }
      },
      setFields<T = object >(state: FormDataState<T>, action:PayloadAction<FormDataState<T>["data"]>) {
         return {
            ...state,
            data : {
               ...state.data,
               ...action.payload
            }
         }
      },
      resetValidation(state: FormDataState){
         return {
            ...state,
            validation: {}
         }
      },
      setValidation(state: FormDataState, action:PayloadAction<FormDataState["validation"]>){
         return {
            ...state,
            validation: {
               ...state.validation,
               ...action.payload
            }
         }
      }
   },
   extraReducers(builder) {
      builder.addMatcher(
         isRejectedAction,
         (state, action) => {}
      )
      .addDefaultCase((state, action)=> {})
   },
});

export const formDataActions = formDataSlices.actions;
export const formDataSelector = (state: RootState): FormDataState => state.formDataState;
export default formDataSlices.reducer;