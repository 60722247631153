import {
   Action,
   AnyAction,
   createSlice,
   PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../redux-tools/store";
import { layoutStateInterface } from "./layout.interface";
const initialState: layoutStateInterface = {
   sidebarOpen: true,
   screenSize: { width: 0, height: 0 },
   menuActiveDetail: [],
   contextMenu: {
      show: false,
      listMenu: [],
      x: 0,
      y: 0,
      indexSelected: null,
      onClose: ()=> null,
   }
};

interface RejectedAction extends Action {
   error: Error;
}
function isRejectedAction(action: AnyAction): action is RejectedAction {
   return action.type.endsWith("rejected");
}
const layoutSlice = createSlice({
   name: "layout",
   initialState,
   reducers: {
      closeSidebar: (state: layoutStateInterface) => ({
         ...state,
         sidebarOpen: (state.screenSize?.width || 0) < 768,
      }),
      toggleSidebar: (state) => {
         return {
            ...state,
            sidebarOpen: !state.sidebarOpen,
         };
      },
      setSizeScreen: (
         state: layoutStateInterface,
         action: PayloadAction<layoutStateInterface["screenSize"]>
      ) => ({ ...state, screenSize: action.payload }),
      setMenuActive: (
         state: layoutStateInterface, 
         action: PayloadAction<layoutStateInterface["menuActiveDetail"]>
      ) => ({ ...state, menuActiveDetail: action.payload}),
      setContextMenu(
         state: layoutStateInterface,
         action: PayloadAction<layoutStateInterface["contextMenu"]>
      ) {
         let screenWidth = window.innerWidth;
         let screenHeight = window.innerHeight;
         let boxHeight = (action.payload?.listMenu || []).length * 26;
         let boxWidth = 220;
         let x2: number | undefined = action.payload?.x;
         let y2: number | undefined = action.payload?.y;
         let offsetY = screenHeight - ((action.payload?.y || 0) + boxHeight);
         let offsetX = screenWidth - ((action.payload?.x || 0) + boxWidth);
         if (offsetY <= 0) {
               y2 = (action.payload?.y || 0) + offsetY - 12;
         }
         if (offsetX <= 0) {
               x2 = (action.payload?.x || 0) + offsetX - 10;
         }
         return {
            ...state,
            contextMenu: { 
               ...action.payload,
               listMenu: action.payload.listMenu.map(value => Object.assign({}, value)),
               x:x2, 
               y:y2,
            },
         };
      },
      closeContextMenu(state: layoutStateInterface) {
         return {
            ...state,
            contextMenu: {
               show: false,
               x: 0,
               y: 0,
               listMenu: [],
               indexSelected: null,
               onClose() {
                  
               },
            },
         };
      },
      
   },
   extraReducers(builder) {
      builder
         .addMatcher(
            isRejectedAction,
            // `action` will be inferred as a RejectedAction due to isRejectedAction being defined as a type guard
            (state, action) => {}
         )
         // and provide a default case if no other handlers matched
         .addDefaultCase((state, action) => {});
   },
});

export const layoutActions = layoutSlice.actions;
export const layoutSelector = (state: RootState): layoutStateInterface => state.layoutState;
export default layoutSlice.reducer;
