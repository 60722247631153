import React from "react";
import {
   CardBodyProps,
   CardFooterProps,
   CardHeaderProps,
   CardProps,
} from "./card.interface";

export default function Card({ size,...props}: CardProps): JSX.Element {
   function getSize(): string {
      if (size === undefined) return "100%";
      if (typeof size === "number") return `${size}px`;
      if (typeof size === "string") return size;
      else return "100%";
   }
   return (
      <div
      
      { ...props}
         style={{
            ...props.style,
            width: getSize(),
         }}
         className={`
      duration-800 ease-in-out transition-all
      bg-white rounded 
       w-[200px] shadow-[0px_1px_3px_rgba(0,0,0,0.3)]
      max-w-full
      ${props.className}`}
      >
         {props.children}
      </div>
   );
}

function Header(props: CardHeaderProps): JSX.Element {
   return (
      <div
      
      { ...props}
         className={` top-0 px-3
            items-center
            min-h-[50px] overflow-hidden 
            border-b-[1pt] border-solid border-gray-200 w-full py-3 ${props.className}`}
      >
         {props.children}
      </div>
   );
}
function Body(props: CardBodyProps): JSX.Element {
   return (
      <div 
      { ...props} style={{ ...props.style}}
         className={` min-h-fit p-3 ${props.className}`}
      >
         {props.children}
      </div>
   );
}
function Footer(props: CardFooterProps): JSX.Element {
   return (
      <div
      { ...props}
         className={` bottom-0 px-3
      items-center
      min-h-[50px] overflow-hidden 
      border-t-[1pt] border-solid border-gray-200 w-full py-3  ${props.className}`}
      >
         {props.children}
      </div>
   );
}

Card.Body = Body;
Card.Footer = Footer;
Card.Header = Header;
