import React, { HTMLProps } from 'react';
import Button from '../button/button';
import classNames from 'classnames';

export default function FileInput(props: HTMLProps<HTMLInputElement>) {
	return (
			<input
            {...props}
				className={classNames(` text-[8pt]
                  appearance-none
                  border rounded w-full py-0 px-0 
                  text-gray-700 leading-tight
                  focus:outline-primary-500 
                  focus:invalid:border-danger file:bg-primary file:py-2
                  file:text-white file:pr-5 file:pl-3 cursor-pointer 
                  file:shadow-sm
                  file:hover:bg-primary-700 file:active:bg-primary-500  file:cursor-pointer
                  file:ring-0
               `,props.className)}
				type="file"
            
			/>
	);
}
