import React, { createContext, useContext, useState } from 'react';
import { ModalProps, ModalBodyProps, ModalFooterProps, ModalHeaderProps, ModalState, ModalSizeType } from './modal.interface';

const modalContext = createContext<ModalState>({
	state: {},
	actions: {},
});

function getSize(size: ModalSizeType): string {
	let sizeObj = {
		sm: 'min-w-[300px]',
		md: 'min-w-[450px] ',
		lg: 'min-w-[600px] ',
		xl: 'min-w-[900px]',
		'2xl': 'min-w-[1200px]',
		full: 'w-screen',
	};
	if (!['sm', 'md', 'lg', 'xl', 'full', '2xl'].some(v => v === size)) throw Error(`Size must be "sm" | "md" | "lg" | "xl" | 2xl`);
	return sizeObj[size];
}

export default function Modal(props: ModalProps): JSX.Element {
	const [dialogClassName, setDialogClassName] = useState<string>('');
	const [inModal, setInModal] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [position, setPosition] = useState<string>('top-0 right-0 h-screen w-full ');
	React.useEffect(
		function () {
			if (props.showModal) {
				// document.body.style.overflow = "hidden";
				// document.body.classList.add("overflow-hidden");
				setShowModal(true);
				setTimeout(() => setInModal(true), 200);
			} else {
				// document.body.style.overflow = "auto";
				// document.body.classList.remove("overflow-hidden");
				setInModal(false);
				setTimeout(() => setShowModal(false), 300);
			}
		},
		[props.showModal],
	);

	return (
		<modalContext.Provider
			value={{
				state: { size: props.size },
				actions: {
					onHide: props.onHide,
				},
			}}
		>
			<dialog
				id="backdrop-modal"
				onClick={e => {
					const target = e.target as HTMLElement;
					if (target.id === 'backdrop-modal') {
						if (props.backdrop === 'static') {
							setDialogClassName('animate-shake');
							setTimeout(() => setDialogClassName(''), 500);
						} else {
							props.onHide?.();
						}
					}
				}}
				className={` 
               fixed  ${position} backdrop-blur-sm z-[80] bg-transparent
               justify-center items-center h-modal
               duration-800 ease-in-out transform transition-all overflow-y-auto
            ${showModal ? 'grid' : 'hidden'} 
      `}
			>
				<div
					style={{
						...props.style,
					}}
					className={`${dialogClassName} my-5 border-2 border-gray-300
                  duration-800 ease-in-out transform transition-all
                  bg-white rounded h-auto
                   shadow-md 
                  ${inModal ? 'translate-y-0' : '-translate-y-[500%]'}
                  ${getSize(props.size || 'md')} 
                  max-w-full
                  ${props.className}`}
				>
					{props.children}
				</div>
			</dialog>
		</modalContext.Provider>
	);
}
const useModal = (): ModalState => useContext(modalContext);
Modal.Header = function Header(props: ModalHeaderProps) {
	const { actions, state } = useModal();
	return (
		<div className="flex justify-center items-center min-h-[50px] max-h-[150px] overflow-hidden border-b-[1pt] border-solid border-gray-200 w-full ">
			{props.closeBtn && (
				<span onClick={() => actions.onHide?.()} className="absolute mr-2 right-2 cursor-pointer z-10">
					X
				</span>
			)}
			<div className=" w-full p-2">{props.children}</div>
		</div>
	);
};
Modal.Body = function Body(props: ModalBodyProps) {
	const { actions, state } = useModal();
	// ${ state.size === "full" ? "h-[calc(100vh_-_150px)]" : "min-h-[50px]"}
	return (
		<div className={`flex justify-center items-center w-full min-h-[50px] `}>
			<div className=" w-full p-2 ">{props.children}</div>
		</div>
	);
};
Modal.Footer = function Footer(props: ModalFooterProps) {
	return (
		<div className="flex bottom-0 justify-center items-center min-h-[50px] max-h-[150px] overflow-hidden border-t-[1pt] border-solid border-gray-200 w-full ">
			<div className=" w-full p-2">{props.children}</div>
		</div>
	);
};
