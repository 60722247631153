import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Swal from "sweetalert2";
import { axiosBaseQuery } from "../../../_module/helper/api";

const dropdown = createApi({
   baseQuery: axiosBaseQuery({
      baseUrl: "",
   }),
   reducerPath: "dropdown",
   tagTypes: ["/dropdown"],
   endpoints: (builder) => ({
      getAllDropdownByCategory: builder.query({
         query: ({
            url="",
            method= "POST",
            queryParam= undefined,
            data= undefined
         }: {
            url: string,
            method?: "POST" | "GET" | "DELETE",
            queryParam?: object,
            data?: object
         }) => ({
            url,
            queryParam,
            method,
            data,
            
         }),
         transformResponse(resp: any) {
            if (resp.resp_code === "00") return resp.resp_data;
            else {
               Swal.fire({
                  title:"Error",
                  icon:"info",
                  html: resp.resp_msg
               })
               return []
            };
         },
      }),
   }),
});

export const {
   useGetAllDropdownByCategoryQuery,
   useLazyGetAllDropdownByCategoryQuery,
} = dropdown;

export const { getAllDropdownByCategory } = dropdown.endpoints;
export default dropdown;
