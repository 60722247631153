import * as React from "react";
import { ButtonColourType, ButtonProps, ButtonSizeType } from "./button.interface";

function getColour(colourType: ButtonColourType): string {
   switch(colourType){
      case "danger":
         return `bg-danger 
         hover:bg-danger-600 active:bg-danger  disabled:bg-danger-400
         `;
      case "info":
         return `bg-info 
         hover:bg-info-600 active:bg-info 
         `;
      case "primary":
         return `bg-primary 
         hover:bg-primary-600 active:bg-primary  disabled:bg-primary-400
         `;
      case "warning":
         return `bg-warning 
         hover:bg-warning-600 active:bg-warning disabled:bg-warning-400
         `;
      case "secondary":
         return `bg-secondary 
         hover:bg-secondary-600 active:bg-secondary disabled:bg-secondary-400
         `;
      case "success" :
         return `bg-success 
         hover:bg-success-600 active:bg-success disabled:bg-success-400
         `;
      default:
         return `bg-primary 
         hover:bg-primary-600 active:bg-primary disabled:bg-primary-400 `
   }
}

function getSize(size: ButtonSizeType): string {
   switch(size){
      case "sm":
         return "py-[4px]";
      case "lg":
         return "py-[6px]";
      default:
         return "py-[6px]"
   }
}

export default function Button (_props: ButtonProps): JSX.Element {
   let props = { ..._props };
   if (props.size === undefined) props.size = "lg";
   props.className = `
       ${getColour(props.color)} ${getSize(props.size)}
      focus:outline-none
      text-[10pt] px-3
      text-white rounded mr-2 ${props.className}
      `
   if(props.type=== undefined) props.type="button"
   return <button {...props}>{props.children}</button>;
}
