import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
   BaseQueryFn,
   EndpointDefinition,
   Api,
   Module,
   buildCreateApi,
   coreModule,
   BaseQueryApi,
   
} from "@reduxjs/toolkit/query";

export function createUrlForm(data: any) {
	var formBody: any = [];
	for (var property in data) {
		var encodedKey = encodeURIComponent(property);
		var encodedValue = encodeURIComponent(data[property]);
		formBody.push(encodedKey + '=' + encodedValue);
	}
	formBody = formBody.join('&');
	return formBody;
}


export const axiosBaseQuery =
   (
      { baseUrl }: { baseUrl: string } = { baseUrl: "" }
   ): BaseQueryFn<
      {
         url: string;
         method?: AxiosRequestConfig["method"] | "get";
         data?: AxiosRequestConfig["data"];
         params?: AxiosRequestConfig["params"];
         queryParam?: {};
         headers?: object;
      },
      BaseQueryApi,
      {}
   > =>
   async ({ url, method, data, params, queryParam, headers = {} }) => {
      let uri = url
      if (queryParam) {
         var keys = Object.keys(queryParam);
         keys.forEach((val, key) => {
             if (key == 0) uri = uri + '?';
             uri = uri + (val + '=' + queryParam[val as keyof typeof queryParam]);
             if (key != keys.length - 1) uri = uri + '&';
         });
     }
      try {
         const result = await axios({
            url: baseUrl + uri, 
            headers: {
               ...headers,
            },
            method,
            data,
            params,
         });
         return { data: result.data };
      } catch (axiosError) {
         let err = axiosError as AxiosError;
         return {
            error: {
               status: err.response?.status,
               data: err.response?.data || err.message,
            },
         };
      }
   };


   export const axiosBaseQueryWithAuth =(
      { baseUrl }: { baseUrl: string } = { baseUrl: "" }
   ): BaseQueryFn<
      {
         url: string;
         method?: AxiosRequestConfig["method"];
         data?: AxiosRequestConfig["data"];
         params?: AxiosRequestConfig["params"];
         queryParam?: {};
         headers?: object;
      },
      unknown,
      unknown
   > =>
   async ({ url, method="GET", data, params, queryParam, headers ={} }, api,extraOptions) => {
      let uri = url
      if (queryParam) {
         var keys = Object.keys(queryParam);
         keys.forEach((val, key) => {
             if (key == 0) uri = uri + '?';
             uri = uri + (val + '=' + queryParam[val as keyof typeof queryParam]);
             if (key != keys.length - 1) uri = uri + '&';
         });
     }
      try {
         const result = await axios({
            headers: {
               ...headers
            },
            url: baseUrl + uri,
            method,
            data,
            params,
         });

         // const refreshResult = await axiosBaseQuery({url: '/refreshToken'}, api, extraOptions)
         // if (refreshResult.data) {
         //    // store the new token
         //    // api.dispatch(tokenReceived(refreshResult.data))
         //    // retry the initial query
         //    // result = await baseQuery(args, api, extraOptions)
         // } else {
         //    // api.dispatch(loggedOut())
         // }
         if (result.status === 401) {
            return { data: {
               error: "",
               status: 401
            } };
         }
         else if(result.status >= 200){
            return { data: result.data };
         }else {
            return  { 
               data: result.data 
            }
         }
      } catch (axiosError) {
         let err = axiosError as AxiosError;
         return {
            error: {
               status: err.response?.status,
               data: err.response?.data || err.message,
            },
         };
      }
   };