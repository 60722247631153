import { PropsWithChildren, createContext, useContext } from "react";

const context = createContext({})

export function DokumentasiProvider(props: PropsWithChildren) {

   return <context.Provider value={{}}>
      {props.children}
   </context.Provider>
}


export default function useDokumentasi(){ return useContext(context) }