




export function setProperty<ValueType = any, Rsp = object>(obj: Rsp, path: string, value: ValueType): Rsp {
    const [head, ...rest] = path.split('.')
    if( !obj[head as keyof typeof obj]) obj = { ...obj, [head] : {}}
    return {
        ...obj,
        [head]: rest.length
            ? setProperty(obj[head as keyof typeof obj ], rest.join('.'), value)
            : value
    }
 }
 
 export function getProperty<ValueType = string>(
     obj: object, path: string
 ): ValueType {
     const [head, ...rest] = path.split('.')
     if( !obj[head as keyof typeof obj]) obj = { ...obj, [head] : rest.length ? {} : ""}
     let newObj = obj[head as keyof typeof obj]
     return rest.length 
         ? getProperty<ValueType>(newObj, rest.join('.'))
         :  newObj
 
 }