import { useCallback, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { layoutStateInterface } from './layout.interface';
import { useSelector, useDispatch } from 'react-redux';
import { layoutActions, layoutSelector } from './layout.slices';
import { FiLogOut } from 'react-icons/fi';
import useAuth from 'redux-tools/slices/auth/auth.hook';
export default function Navbar<FC>(props: any) {
	const layoutState: layoutStateInterface = useSelector(layoutSelector);
	const dispatch = useDispatch();
	let cn = classNames({
		'md:ml-[270px]': layoutState.sidebarOpen,
		'md:w-[calc(100%_-_270px)]': layoutState.sidebarOpen,
		'md:w-full': !layoutState.sidebarOpen,
		'md:ml-[0px]': !layoutState.sidebarOpen,
		'ml-[270px]': !layoutState.sidebarOpen,
		'w-[calc(100%_-_270px)]': !layoutState.sidebarOpen,
		'ml-0': layoutState.sidebarOpen,
		'w-full': layoutState.sidebarOpen,
	});
	return (
		<div className={`fixed bg-navbar z-10 top-0 ${cn}  bg-white shadow-[0px_1px_3px_rgba(0,0,0,0.3)] h-[50px]  ease-in-out duration-300`}>
			<div className="flex justify-between items-center md:space-x-10">
				<div className="flex justify-start mr-2 my-1 align-middle">
					<button
						id="sidebar"
						onClick={() => {
							dispatch(layoutActions.toggleSidebar());
						}}
						type="button"
						className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
						aria-expanded="false"
					>
						<span id="sidebar" className="sr-only">
							Open menu
						</span>

						<svg
							id="sidebar"
							className="h-6 w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					</button>
				</div>
				<div
					className={`flex justify-end mr-2 my-1 items-center align-middle px-3
               ${classNames({
					'max-md:hidden': !layoutState.sidebarOpen,
				})}
            `}
				>
					<DropdownUser />
				</div>
			</div>
		</div>
	);
}

function DropdownUser() {
	const disp = useDispatch();
	const [open, setOpen] = useState<boolean>(false);
	const {
		authAction,
		authState
	} = useAuth();
	const close = useCallback(() => setOpen(false), [setOpen]);
	const reffDrop = useRef<HTMLDivElement>(null);
	const onclickAnother = function (e: MouseEvent) {
		if (reffDrop.current && !reffDrop.current?.contains(e.target as HTMLElement)) {
			close();
		}
	};
	useEffect(() => {
		document.addEventListener('mouseup', onclickAnother);
		return () => {
			document.removeEventListener('mouseup', onclickAnother);
		};
	}, []);
	return (
		<div ref={reffDrop}>
			<button
				onClick={() => setOpen(v => !v)}
				id="sidebar"
				type="button"
				className="
               bg-white rounded-md p-2 inline-flex 
               items-center justify-center text-gray-400 
               hover:text-gray-500 hover:bg-gray-100 
               focus:outline-none focus:ring-2 focus:ring-inset
            "
				aria-expanded="false"
			>
				<img className="w-7 h-7 rounded-full mr-2" width={200} height={200} src="/image/dummyimage.png" alt="foto profile" />
				<h5 className="text-md font-bold">{ authState.userLogin?.full_name }</h5>
			</button>
			<div
				id="dropdownNavbar"
				className={`z-10 ${classNames({ hidden: !open })} 
            duration-800 ease-in-out transition-all
            absolute top-14 font-normal bg-white divide-y divide-gray-100 rounded-md shadow-md w-64 right-1 py-1 px-2`} //dark:bg-gray-700 dark:divide-gray-600
			>
				<div className=" items-center inline-flex justify-center">
					<div className="mt-2 mr-3">
						<img className=" w-14 h-14 rounded-full mr-2" width={200} height={200} src="/image/dummyimage.png" alt="profile" />
					</div>
					<div>
						<h5 className="text-md font-bold">{ authState.userLogin?.full_name }</h5>
                  <span className="text-sm text-gray-500"> { authState.userLogin?.role_name } </span>
					</div>
				</div>
				<div>
					<ul className="py-1 text-sm text-gray-700 space-y-1 " aria-labelledby="dropdownLargeButton">
						{/* <li>
							<a className="block px-4 py-2 hover:bg-gray-100" href="#">
								TEST
							</a>
						</li> */}
						<li className="border-top-[1px] border-gray-500 border-solid">
							<button
								onClick={() => authAction.doLogout()}
								className="inline-flex items-center justify-center px-4 py-2 hover:bg-gray-100 w-full text-danger text-left"
							>
								<FiLogOut /> <span className="ml-1"> Sign Out </span>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
