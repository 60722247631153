// import AsyncSelect from "react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactSelect from "react-select";
import { AsyncSelect2Props } from "./async-select.interface";
import * as uuid from 'uuid'
import React from "react";

export default function AsyncSelect (_props: AsyncSelect2Props) {
   const [src, setSrc] = useState("");
   const [optionLoaded, setOptLoaded] = useState(false);
   const [loading, setLoading] = useState(false);
   const [focused, setFocused] = useState(false);
   const [dataSelected, setDataSelected] = useState<{
      value: string;
      label: string;
   }>({
      label: "",
      value: "",
   });
   const [options, setDataOptions] = useState<
      Array<{ value: string; label: string }>
   >([]);
   let props = { ..._props };
   let opt: Array<{ value: string; label: string }> = [];
   let place: Array<{ value: string; label: string }> = [
      {
         value: "",
         label: ` -- Select ${props.placeholder || ""} --`,
      },
   ];
   if (props.options) opt = props.options;
   // if (props.value) {
   //    let data = options;
   //    props.value = data?.find((v) => v.value === props.value);
   //    props.defaultInputValue = props.value?.label;
   // } else {
   //    props.value = place[0];
   // }
   // },[])

   let height = 31;
   props.styles = {
      ...props.styles,
      control: (styles) => {
         // console.log(styles)
         return {
            ...styles,
            minHeight: 10,
            height,
            // zIndex: 10,
            padding: 0,
            ...(props.error?.isErr
               ? {
                    border: "#dc3545 1px solid",
                 }
               : {}),
         };
      },
      placeholder: (styles) => {
         return {
            ...styles,
            top: 13,
            // position:"static",
            // paddingTop:20
         };
      },
      input: (styles) => {
         // console.log(styles)
         return {
            ...styles,
            paddingBottom: 10,
            position: "absolute",
            // height:10,
            // margin:3
         };
      },
      indicatorsContainer: (s) => {
         return {
            ...s,
            padding: "4px 1px",
            height,
         };
      },
      valueContainer: (styles) => ({
         ...styles,
         height: 20,
         minHeight: 10,
         position: "static",
         // zIndex:20
      }),
      indicatorSeparator: (style) => ({
         display: "none",
      }),
      container: (styles, s) => {
         // s.
      return   {
         ...styles,
         height,
         minHeight: 10,
         borderRadius: 1,
         ...( s.isFocused ? {zIndex: 50} : {})
      }
      
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
         return {
            ...styles,
            color: isFocused ? "white" : "black",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: 1,
            paddingLeft: 10,
            backgroundColor: isFocused ? "#007bff" : "default",
            fontSize: 10,
            innerHeight: 6,
            // zIndex: 50,
            // height: 20
         };
      },
   };
   props.onFocus = function (e) {
      if (typeof _props.onFocus === "function") _props.onFocus?.(e);
      if (props.withCallApi && (!optionLoaded)) search();
   };


   useEffect(function(){
      if(props.autoLoadOptions) {
         if (props.withCallApi && (!optionLoaded)) search();
      }
   },[])
   const search = useCallback(
      async function search(withSearch = true) {
         setLoading(true);
         if (props.asyncLoadData && props.withCallApi) {
            try {
               let data = await props.asyncLoadData?.(withSearch ? src : "");
               let newSelected: { value: string; label: string } | undefined =
                  dataSelected;
               if (props.valueDisplay === "") {
                  newSelected = data.find((v) => v.value === props.value);
                  if (!newSelected) newSelected = dataSelected;
               }
               let dataX: Array<{ value: string; label: string }> = [];
               if (newSelected && !data.some((v) => v.value === newSelected?.value)) {
                  dataX = [dataSelected];
               }
               setDataOptions([...dataX, ...data]);
               setLoading(false)
               setOptLoaded(true);
            } catch (error) {
               setLoading(false)
            }
         } else {
            let data = await filter(src);
            setDataOptions(data);
         }
         // setSrc("")
         setLoading(false);
      }
      , [setLoading, props.asyncLoadData, props.withCallApi, props.valueDisplay,dataSelected, setDataOptions, setOptLoaded]
   )
   const filter: any = 
   useCallback (
      async (inputValue: string) => {
         return [
            ...place,
            ...opt.filter(
               (i) =>
                  i.label
                     .toLowerCase()
                     .includes(String(inputValue || "").toLowerCase()) ||
                  i.value
                     .toLocaleLowerCase()
                     .includes(String(inputValue || "").toLocaleLowerCase())
            ),
         ];
      }
      ,[]
   )
   

   const uuidGenerated = useMemo( () => {
      return  uuid.v4()
   }, [props.name])

   
   useEffect(
      function () {
         let sel = options?.find((v) => v.value === props.value);
        
         if (props.value !== "" && props.value !== null && props.value !== undefined) {
            if (options.length <= 2)
            setDataOptions([
               ...place,
               {
                  label: `${ props.value} - ${props.valueDisplay || ""}`,
                  value: props.value,
               },
            ]);
            setDataSelected({
               value: props.value,
               label: sel ? `${ props.value} - ${sel.label || ""}` : `${ props.value} - ${props.valueDisplay || ""}`,
            });
         }
         if (
            props.value === "" ||
            props.value === undefined ||
            props.value === null
         ) {
            setDataSelected(place[0]);
         }
      },
      [props.value, props.valueDisplay]
   );

   return (
      <>
         <div className=" w-full text-[8pt]">
            <ReactSelect
               id="long-value-select"
               instanceId="long-value-select"
               // defaultValue={dataSelected}
               // value={dataSelected}
               onBlur={() => {
                  if(options.length <=1) setOptLoaded(false)
                  setFocused(false);
               }}
               onKeyDown={(e) => {
                  if (props.enterSearch) {
                     if (e.keyCode === 13) {
                        e.preventDefault();
                        search();
                     }
                  }
               }}
               isSearchable={true}
               {...(props.enterSearch
                  ? {
                     filterOption: (opt, input) => true,
                     inputValue: src,
                     onInputChange: (e) => setSrc(e),
                  }
                  : {})}
               // onInputChange={ e => enterSearch ? setSrc(e) : true}
               placeholder={
                  props.enterSearch && focused
                     ? `Enter for search`
                     : ` -- Select ${props.placeholder || ""} --`
               }
               // defaultOptions ={options}
               isLoading={loading}
               // loadOptions={loadOptions}
               options={options.map(({ value, label }) => ({
                  value, label: `${value} - ${label}`
               }))}
               {...props}
               defaultValue={dataSelected}
               value={dataSelected}
               isDisabled={props.disabled}
            />
         </div>
         {
             props.error?.isErr &&
            <span className="text-danger">{ props.error.errMsg}</span>
         }
      </>
   );
}
