import "./App.css";
import { Navigate, Outlet, Router } from "react-router";
import { RouterProvider } from "react-router-dom";
import { LoadingPage } from "./components";
import { Provider, useDispatch } from "react-redux";
import store, { AppDispatch } from "./redux-tools/store";
import { RouteRender } from "./config/router.config";
import React, { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import useAuth from "redux-tools/slices/auth/auth.hook";
import { authActions } from "redux-tools/slices/auth/auth.slices";

const InitPage = () => {
   const disp: AppDispatch = useDispatch();
   const [cookies, setCookies, removeCookies] = useCookies(["access_token"]);
   const { authAction, authState } = useAuth();

   useEffect(
      function () {
         setTimeout(function () {
            disp(authActions.setIsInit(true));
            if (cookies.access_token) {
               disp(authActions.setIsauth(true));
            } else {
               disp(authActions.setIsauth(false));
            }
         }, 500);
         
      },
      []
   );

   return (
      <>
         {/* <LoadingPage
            isLoading={!authState.isInitialized || authState.isLoading}
         >
            {authState.isLoading
               ? "Getting user login ..."
               : " Initialized User ..."}
         </LoadingPage> */}
      </>
   );
};

function App() {
   return (
      <>
         <CookiesProvider>
            <Provider store={store}>
               <InitPage />
               <React.Suspense fallback={<LoadingPage isLoading={true} />}>
                 <RouteRender />
               </React.Suspense>
            </Provider>
         </CookiesProvider>
      </>
   );
}

export default App;
