import classNames from 'classnames';
import { LookupSelectorProps } from './lookup-selector.interface';
import { Modal, Button, TableGrid, Input } from '..';
import { useState, useEffect } from 'react';

export default function LookupSelector (props: LookupSelectorProps): JSX.Element {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [filter, setFilter] = useState<object>({});
	const [pagination, setPagination] = useState({ page: 1, show: 15 });
	useEffect(function(){
		if(showModal){
			setFilter(props.defaultFilter || {})
			props.onFilter?.(props.defaultFilter || {}, { page:1, show:15 })
		}
	},[showModal])
	return (
		<>
			<Modal
				size="xl"
				backdrop="static"
				showModal={showModal}
				onHide={() => {
					// console.log("hiding")
					setShowModal(false);
				}}
			>
				<Modal.Header closeBtn={true}> {props.placeholder || 'Data'} </Modal.Header>
				<Modal.Body>
					{props.filterData && (
						<>
							<div className="grid grid-cols-4 my-2 gap-2">
								{props.filterData?.map((v, i) => (
									<div key={i}>
										<Input.Label>{v.title}</Input.Label>
										{v.typeInput === 'select' ? (
											<Input.Select
												value={filter[v.name as keyof typeof filter]}
												onChange={e => {
													setFilter(vx => ({
														...vx,
														[v.name as keyof typeof filter]: (e.target as HTMLSelectElement).value,
													}));
												}}
											>
												{v.options.map((cc, i) => (
													<option key={i} value={cc.value}>{cc.display}</option>
												))}
											</Input.Select>
										) : v.typeInput === 'number' ? (
											<Input.Number
												type="numeric"
												value={filter[v.name as keyof typeof filter]}
												onChange={e => {
													setFilter(vx => ({
														...vx,
														[v.name as keyof typeof filter]: (e.target as HTMLSelectElement).value,
													}));
												}}
											/>
										) : (
											<Input.Text
												value={filter[v.name as keyof typeof filter]}
												onChange={e => {
													setFilter(vx => ({
														...vx,
														[v.name as keyof typeof filter]: (e.target as HTMLSelectElement).value,
													}));
												}}
											/>
										)}
									</div>
								))}
								{
								!props.disabled &&  props.filterData?.length &&  <div>
									<Input.Label>&nbsp;</Input.Label>
									<Button type='button' size='lg' color="primary" onClick={()=> {
										setPagination({
											page:1, show:15
										})
										props.onFilter?.(filter, { page:1, show:15 })
									}}> Submit </Button>
								</div>
								}
							</div>
						</>
					)}
					<div>
						<TableGrid
							maxHeight={410}
							columns={props?.columns || []}
							data={props.data || []}
							actionsMenu={[
								{
									"id" : "",
									"name": `Pilih ${props.placeholder}`,
									 onClick(r){
										setShowModal(false)
										props.onChange?.(r[props.valueField as keyof typeof r], r)
									 }
								}
							]}
							isLoading={props.isLoading}
							iterationNumber={true}
							withAction={true}
							pagination={true}
							onChangePage={p => {
								props.onFilter?.(filter, p);
								setPagination({
									page: p.page,
									show: p.show,
								});
							}}
							onChangeShow={p => {
								props.onFilter?.(filter, p);
								setPagination({
									page: p.page,
									show: p.show,
								});
							}}
							currentPage={pagination.page}
							currentShow={pagination.show}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="flex justify-end ">
						<Button
							onClick={() => {
								setShowModal(false);
							}}
							color="secondary"
						>
							close
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
			<div className="flex text-[8pt]">
				<input
					readOnly={true}
					// onFocus={() => {
					// 	setShowModal(true);
					// }}
					value={props.value}
					onChange={e => e}
					placeholder={props.placeholder}
					className={classNames(
						` text-[8pt]
               appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight
               focus:outline-primary-500 focus:shadow-outline] 
               focus:invalid:border-danger
               `,
						{
							' border-[#b3b3b3]': !props.error?.isErr,
							'border-danger': props.error?.isErr,
						},
					)}
				/>{' '}
				{!props.disabled && <button
					type="button"
					onClick={() => {
						setShowModal(v => !v);
					}}
					className="py-1 px-2 bg-primary text-white rounded-r disabled:bg-gray-500 hover:bg-primary-600 active:bg-primary"
				>
					...
				</button>}
			</div>

			{props.error?.isErr && <span className="text-danger">{props.error.errMsg}</span>}
		</>
	);
}
