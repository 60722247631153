import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, TableGrid } from '..';
import BodyFormUI from '../default-form/default-form.body';
import { TableGridProps } from '../table-grid/table-grid.interface';
import GenerateForm from './generate-form';
import { GenerateFormGridProps, GenerateFormProps } from './generate-form.interface';

export default function GenerateFormGrid(props: GenerateFormGridProps) {
	const [shoModal, setShowModal] = useState<boolean>(false);
	const [formState, setFormState] = useState<any>({});
	const [validation, dataValidation] = useState<any>({});
	const [rowActive, setRowActive] = useState<number | null | undefined>(null);
	let wAction: boolean = (!props.readOnly || false) && (props.withAction || false);
	let actions: TableGridProps['actionsMenu'] = [
		{
			id: '',
			name: 'Edit',
			onClick(data, menu, indexMenu, i) {
				setFormState(data);
				setRowActive(i);
				setShowModal(true);
			},
		},
		{
			id: '',
			name: 'Delete',
			onClick(data, menu, indexMenu, index) {
				console.log(index);
				let newData = (props.data || []).filter((f, ff) => ff !== index);
				props.onChange(newData);
			},
		},
	];
	function saveChanges() {
		if (rowActive !== undefined && rowActive !== null) {
			props.onChange(props.data.map((c, b) => (rowActive === b ? formState : c)));
		} else {
			props.onChange([...props.data, formState]);
		}
		setRowActive(undefined);
		setShowModal(false);
	}
	if (props.customActions) {
		actions = (props.customActions || []).map(c => ({
			id: '',
			name: c.name,
			onClick(data, menu, indexMenu) {
				c.onClick(data, data.__index, () => null);
			},
		}));
	}
	function resetForm() {
		setFormState(props.initialForm || {});
	}
	function closeModal() {
		setShowModal(false);
		resetForm();
		setRowActive(undefined);
	}

	return (
		<>
			<Modal showModal={shoModal} onHide={() => closeModal()} backdrop="static" size="lg">
				<Modal.Header closeBtn>
					<span className=" font-bold text-sm">{props.title} </span>
				</Modal.Header>
				<Modal.Body>
					<div className="w-full">
						{props.fieldInput.map((h, i) => (
							<BodyFormUI key={i} header={props.title}>
								<div className="grid grid-cols-2 gap-3">
									{h.field.map((f: GenerateFormProps, fi: number) => (
										<div key={`${f.name}-${i}-${fi}`}>
											<Input.Label>{f.title}</Input.Label>
											<GenerateForm
												{
													...(f.type === 'async-select'
													? ({
															type: 'async-select',
															title: f.title,
															setup: f.setup,
													  })
													: f.type === "lookup" ? ({
														type : "lookup",
														setup: f.setup
													}) : 
													f.type === "select" ? ({
														type : "select",
														setup : f.setup
													}) : f.type === "checkbox" ? ({
														type: "checkbox",
														setup: f.setup
													}) : f.type === "string" ? ({
														type: "string"
													}) : f.type === "text" ? ({
														type: "text"
													}) : f.type === "number" ? ({
														type: "number"
													})  : ({
														type: "numeric"
													})
													)}
												manualData={true}
												setFieldFn={setFormState}
												formDataField={formState}
												validationData={{}}
												otherProps={f.otherProps}
												name={ f.name}
												isLoading={f.isLoading}
											/>
										</div>
									))}
								</div>
							</BodyFormUI>
						))}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className="w-full flex justify-end">
						<Button
							color="primary"
							onClick={() => {
								saveChanges();
							}}
						>
							Save
						</Button>
						<Button color="secondary" onClick={() => closeModal()}>
							Close
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
			<div className="grid gap-1 grid-cols-1">
				{!props.readOnly && (
					<div className=" w-full flex justify-end">
						<Button
							onClick={() => {
								setShowModal(true);
								setRowActive(undefined);
								resetForm();
							}}
							size="sm"
						>
							+ Add
						</Button>
					</div>
				)}
				<div>
					<TableGrid
						columns={props.columns || []}
						data={props.data || []}
						withAction={wAction}
						actionsMenu={actions}
						iterationNumber={true}
						pagination={false}
						isLoading={props.isLoading || false}
					/>
				</div>
			</div>
		</>
	);
}
