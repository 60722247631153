import classNames from "classnames";
import * as Fa from "react-icons/fa";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import * as Tb from "react-icons/tb";
import * as Tfi from "react-icons/tfi";
import * as Gi from "react-icons/gi";
import { useLocation, useNavigate, useNavigation } from "react-router";
import { Link } from "react-router-dom";

export default function PublicMenu() {
   let className = "duration-150 transform transition-all";

   const nav = useNavigate();
   const location = useLocation();
   return (
      <>
         {/* on desktop */}
         <div className=" w-full h-[50px] bg-sidebar fixed z-30">
            <div className="w-full h-full space-x-1 max-lg:hidden flex justify-start items-center px-5">
               <div className="">
                  <Link
                     className={classNames(
                        `text-white ${className} py-1 px-3 text-sm hover:bg-sidebar-600 rounded font-bold flex justify-center items-center space-x-1`,
                        {
                           "bg-sidebar-600": location.pathname === "/",
                        }
                     )}
                     to={"/"}
                  >
                     <div>
                        <Bs.BsHouse />
                     </div>
                     <div>Beranda </div>
                  </Link>
               </div>
               <div className="">
                  <Link
                     className={classNames(
                        `text-white ${className} py-1 px-3 rounded  text-sm hover:bg-sidebar-600 font-bold flex justify-center items-center space-x-1`,
                        {
                           "bg-sidebar-600": location.pathname === "/laporan",
                        }
                     )}
                     to={"/laporan"}
                  >
                     <div>
                        <Tb.TbReport />
                     </div>
                     <div>Laporan </div>
                  </Link>
               </div>
               <div className="">
                  <Link
                     className={classNames(
                        `text-white ${className} py-1 px-3 rounded  text-sm hover:bg-sidebar-600 font-bold flex justify-center items-center space-x-1`,
                        {
                           "bg-sidebar-600": location.pathname === "/shodaqoh",
                        }
                     )}
                     to={"/shodaqoh"}
                  >
                     <div>
                        <Gi.GiReceiveMoney />
                     </div>
                     <div>Shodaqoh </div>
                  </Link>
               </div>
               <div className="">
                  <Link
                     className={classNames(
                        `text-white ${className} py-1 px-3 rounded  text-sm hover:bg-sidebar-600 font-bold flex justify-center items-center space-x-1`,
                        {
                           "bg-sidebar-600": location.pathname === "/gallery",
                        }
                     )}
                     to={"/gallery"}
                  >
                     <div>
                     <Tfi.TfiGallery />
                     </div>
                     <div>Gallery </div>
                  </Link>
               </div>
               <div className="">
                  <Link
                     className={classNames(
                        `text-white ${className} py-1 px-3 rounded  text-sm hover:bg-sidebar-600 font-bold flex justify-center items-center space-x-1`,
                        {
                           "bg-sidebar-600": location.pathname === "/about",
                        }
                     )}
                     to={"/about"}
                  >
                     <div>
                     <Tb.TbAdjustments />
                     </div>
                     <div>Informasi </div>
                  </Link>
               </div>
            </div>
         </div>

         {/* on mobile */}
         <div
            className={classNames(
               ` shadow-inner
            max-lg:flex hidden fixed bottom-0 z-30 left-0 w-screen h-[60px] py-2 bg-sidebar
             justify-between items-center px-3  rounded-t-2xl
         `,
               {
                  [className]: true,
               }
            )}
         >
            <div className="w-1/5  text-center">
               <Link
                  to={"/laporan"}
                  className={classNames(
                     `h-full rounded-lg flex active:animate-ping active:bg-sidebar-600 justify-center items-center text-xs text-center py-2 px-3 text-white font-bold`,
                     {
                        [className]: true,
                        "bg-sidebar-600": location.pathname === "/laporan",
                     }
                  )}
               >
                  <Tb.TbReport size={30} className=" basis-full" />
               </Link>
            </div>
            <div className="w-1/5  text-center">
               <Link
                  to={"/shodaqoh"}
                  className={classNames(
                     `h-full rounded-lg flex active:animate-ping active:bg-sidebar-600 justify-center items-center text-xs text-center py-2 px-3 text-white font-bold`,
                     {
                        "bg-sidebar-600": location.pathname === "/shodaqoh",

                        [className]: true,
                     }
                  )}
               >
                  <Gi.GiReceiveMoney size={30} className=" basis-full" />
               </Link>
            </div>

            <div className="w-1/4 text-center">
               <button
                  onClick={() => nav("/")}
                  className={classNames(
                     `h-full relative border-white active:animate-ping active:bg-sidebar-600 text-white rounded-full border top-[-10px]  border-spacing-3 py-3 px-3  font-bold`,
                     {
                        "bg-sidebar ": !(location.pathname === "/"),
                        "bg-sidebar-600": location.pathname === "/",

                        [className]: true,
                     }
                  )}
               >
                  <Bs.BsHouse size={30} />
               </button>
            </div>

            <div className="w-1/5  text-center">
               <Link
                  to={"/gallery"}
                  className={classNames(
                     `h-full rounded-lg flex active:animate-ping active:bg-sidebar-600 justify-center items-center text-xs text-center py-2 px-3 text-white font-bold`,
                     {
                        "bg-sidebar-600": location.pathname === "/gallery",

                        [className]: true,
                     }
                  )}
               >
                  <Tfi.TfiGallery size={30} className=" basis-full" />
               </Link>
            </div>
            <div className="w-1/5  text-center">
               <Link
                  to={"/about"}
                  className={classNames(
                     `h-full rounded-lg flex active:animate-ping active:bg-sidebar-600 justify-center items-center text-xs text-center py-2 px-3 text-white font-bold`,
                     {
                        "bg-sidebar-600": location.pathname === "/about",

                        [className]: true,
                     }
                  )}
               >
                  {" "}
                  <Tb.TbAdjustments size={30} className=" basis-full" />
               </Link>
            </div>
         </div>
      </>
   );
}
