import { UserListProvider } from "./user-list.provider"

const LPPage = () => {

   return (
      <>
         <div className="">
            <h2>Laporan Kas</h2>
         </div>
      </>
   )
}


export default (props: any) => <UserListProvider>
   <LPPage { ...props} />
</UserListProvider>

