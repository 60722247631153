import { Card, TableGrid, TitlePage } from "components"
import { LaporanKasProvider } from "./laporan-kas.provider"

const LPPage = () => {

   return (
      <>
         <TitlePage title={"Laporan"} subtitle={"Laporan Kas"} />
         <div className="mt-10">
            <div className="mb-3">
               <Card className="min-h-[0px]">
                  <Card.Body className="min-h-0">
                     <div className="grid grid-cols-1 gap-y-2">
                        <div>
                           Bulan September
                        </div>
                        <div className=" border p-2 rounded-md"> 
                           Total Saldo :
                        </div>
                     </div>
                     <div className="grid grid-cols-2 gap-x-2 mt-2">
                        <div className="border p-2 rounded-md">
                           Pengeluaran :
                        </div>
                        <div className="border p-2 rounded-md">
                           Pemasukan :
                        </div>
                     </div>
                  </Card.Body>
               </Card>
            </div>
            <div>
               <Card>
                  <Card.Body>
                     <TableGrid 
                        columns={[
                           { field:"tgl_trx", title:"Tanggal" },
                           { field:"tipe_trx", title:"Tipe Transaksi" },
                           { field:"nominal", title:"Nominal" },
                           { field:"saldo", title:"Saldo" },
                        ]} 
                        isLoading={false} 
                        data={[]} 
                        withAction={false} 
                        iterationNumber={false} 
                     />
                  </Card.Body>
               </Card>
            </div>
         </div>
      </>
   )
}


export default (props: any) => <LaporanKasProvider>
   <LPPage { ...props} />
</LaporanKasProvider>

