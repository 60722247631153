import { Link } from "react-router-dom";
import * as bi from 'react-icons/bi'

export default function ErrorPage(props: any) {
   return (
      <>
         <div className="flex flex-wrap items-center justify-center w-screen min-h-screen bg-sidebar">
            <div className=" w-[30%] max-lg:w-[50%] max-md:w-[80%] text-white text-2xl text-center">
               NOT FOUND | <span className="font-bold"> 404 </span> 
               <div className="text-base text-link underline cursor-pointer">
                  <Link to="/" className="flex items-center space-x-1 justify-center"> 
                  <bi.BiHome className="mr-2"/>
                  Back to Home</Link>
               </div>
            </div>
         </div>
      </>
   );
}
