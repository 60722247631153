import classNames from 'classnames';
import React, {
	createContext,
	ForwardedRef,
	HTMLProps,
	PropsWithChildren,
	RefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	AsyncSelect2Props,
	InputCheckBoxProps,
	InputNumberProps,
	InputSize,
	InputTextProps,
	SelectOptionPorps,
	TextAreaProps,
} from './input.interface';
import { thousandSparator, removeNumbering } from '../../_module/helper/sparatorThousand';
import AsyncSelect from 'react-select';

type FormState = {
	actions: {
		initRefInput: (name: string, refObj: any) => any;
		changeForm: (name: string, value: any) => any;
		setErr?: (s: any) => any;
	};
	state: {
		formData?: Object;
		refInput?: any;
		err?: Array<{
			name: '';
			message: '';
		}>;
	};
	providerInitialized: boolean;
	refForm?: any;
};
const formContext = createContext<FormState>({
	actions: {
		initRefInput() {},
		changeForm() {},
	},
	state: {
		formData: {},
		refInput: {},
	},
	providerInitialized: false,
	refForm: {},
});
const useForm = () => useContext(formContext);

function getSize(size: InputSize): string {
	switch (size) {
		case 'sm':
			return 'py-1';
		case 'lg':
			return 'py-2';
		default:
			return 'py-2';
	}
}

export const Form = React.forwardRef<
	HTMLFormElement,
	{
		onChange?: (formData: Object) => any;
		onSubmit?: (formData: any) => any;
		formData?: Object;
		passingDataMode?: boolean;
	} & React.HTMLAttributes<HTMLFormElement>
>(function Form(_props, _ref) {
	let props = { ..._props };
	let ref = _ref;
	let newref = useRef<HTMLFormElement>(null);
	if (!ref) ref = newref;

	const [refInput, setRefInput] = useState({});
	const [formData, setFormData] = useState<Object>({});
	const changeForm = useCallback<FormState['actions']['changeForm']>(function (name, value) {
		setFormData(form => ({ ...form, [name]: value }));
		setErr(v => v.filter(v => v.name !== name));
	}, []);
	const initRefInput = useCallback(function (name: string, refObj: RefObject<HTMLElement> | undefined) {
		setRefInput(function (c) {
			return {
				...c,
				[name]: refObj,
			};
		});
	}, []);
	useEffect(
		function () {
			if (props.formData && props.passingDataMode) setFormData(props.formData);
			else setFormData({});
		},
		[props.passingDataMode],
	);
	useCallback(
		function () {
			typeof props.onChange === 'function' && props.onChange(formData);
			// setErr([])
		},
		[formData],
	);
	const [err, setErr] = useState<
		Array<{
			name: '';
			message: '';
		}>
	>([]);

	return (
		<form
			className="peer"
			ref={ref}
			autoComplete="off"
			// noValidate
			{...props}
			onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
				e.preventDefault();
				if (typeof _props.onSubmit === 'function') _props.onSubmit(formData);
			}}
			onReset={(e: React.SyntheticEvent<HTMLFormElement>) => {
				setFormData({});
				if (typeof _props.onReset === 'function') _props.onReset(e);
			}}
		>
			<formContext.Provider
				value={{
					actions: {
						initRefInput,
						changeForm,
						setErr,
					},
					state: {
						formData,
						refInput,
						err,
					},
					providerInitialized: true,
					refForm: ref,
				}}
			>
				{props.children}
			</formContext.Provider>
		</form>
	);
});

const Text = React.forwardRef<HTMLInputElement, InputTextProps>(function InputText({ onChangeValue = () => null, ..._props }, _ref) {
	let props = { ..._props };
	let ref: any = _ref;
	let newref = useRef<HTMLInputElement>(null);
	if (!ref) ref = newref;
	props.className = classNames(
		`
      appearance-none
      border rounded w-full ${getSize(props.sizeinput)} px-3 
      text-gray-700 leading-tight
      focus:outline-primary-500 focus:shadow-outline] ${props.className}
      focus:invalid:border-danger
   `,
		{
			' border-[#b3b3b3]': !props.error?.isErr,
			'border-danger': props.error?.isErr,
		},
	);
	if (props.type === undefined) props.type = 'text';

	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();

	let errorMsg = (state?.err || []).find(v => v.name === props.name)?.message;
	useEffect(
		function () {
			if (providerInitialized) changeForm(props.name || '', ref?.current?.value || '');
		},
		[providerInitialized],
	);
	props.onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		let target = e.target as HTMLInputElement;
		if (providerInitialized) {
			changeForm(target.name, target.value);
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);

		if (typeof onChangeValue === 'function') onChangeValue(target.value || '');
	};
	if (providerInitialized) {
		props.value = (state.formData || {})[props.name as keyof typeof state.formData];
		if (props.name === undefined) throw new Error("<Input.Text name='' /> name attributte is required for data name");
		initRefInput(props.name || '', ref);
	}
	useEffect(
		function () {
			if (providerInitialized) {
				initRefInput(props.name || '', ref);
			}
		},
		[props.name],
	);
	return (
		<>
			<input
				value={props.value || ''}
				autoComplete="off"
				autoCorrect="off"
				ref={ref}
				type="text"
				{...props}
				onChange={(e: any) => props.onChange?.(e)}
			/>{' '}
			{errorMsg || (props.error?.isErr && <span className="text-danger">{errorMsg || props.error.errMsg}</span>)}
		</>
	);
});

const Number = React.forwardRef<HTMLInputElement, InputNumberProps>(function InputNumber(
	{ typeInput = 'numeric', onChangeValue = () => null, ..._props },
	_ref,
) {
	let props = { ..._props };
	let ref: any = _ref;
	let newref = useRef<HTMLInputElement>(null);
	if (!ref) ref = newref;
	const [isFocus, setIsFocus] = useState(false);
	props.onFocus = e => {
		_props.onFocus?.(e);
		setIsFocus(true);
	};
	props.onBlur = e => {
		_props.onBlur?.(e);
		setIsFocus(false);
	};
	props.className = classNames(
		`
   appearance-none
   border rounded w-full ${getSize(props.sizeinput)} px-3 
   text-gray-700 leading-tight
   focus:outline-primary-500 focus:shadow-outline] ${props.className}
   focus:invalid:border-danger
`,
		{
			' border-[#b3b3b3]': !props.error?.isErr,
			'border-danger': props.error?.isErr,
		},
	);
	if (props.type === undefined) props.type = 'text';
	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();

	let errorMsg = (state?.err || []).find(v => v.name === props.name)?.message;

	useEffect(
		function () {
			if (providerInitialized) changeForm(props.name || '', ref?.current?.value || '');
		},
		[providerInitialized],
	);

	useEffect(
		function () {
			if (!isFocus) {
				let val = ref?.current?.value;
				if (props.type === 'int') changeForm(props.name || '', parseInt(val));
				else if (props.type === 'numeric') changeForm(props.name || '', val.replace(/[^0-9 \-]/g, ''));
				else changeForm(props.name || '', parseFloat(removeNumbering(val)));
			}
		},
		[isFocus],
	);
	// if(props.)
	props.onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		let target = e.target as HTMLInputElement;
		let val = target.value.replace(/[^0-9 . \-]/g, '');
		if (val === 'NaN') val = '';
		if (providerInitialized) {
			changeForm(target.name, removeNumbering(val));
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);
		if (typeof onChangeValue === 'function') onChangeValue(removeNumbering(val));
	};

	if (providerInitialized) {
		props.value = (state.formData || {})[props.name as keyof typeof state.formData];
		if (typeInput === undefined) {
			typeInput = 'int';
		}

		if (props.name === undefined) throw new Error("<Input.Number name='' /> name attributte is required for data name");
	}
	useEffect(
		function () {
			if (providerInitialized) {
				initRefInput(props.name || '', ref);
			}
		},
		[props.name],
	);

	if (typeInput === 'currency') props.value = thousandSparator(String(props.value || ''));
	if (typeInput === 'int') props.value = parseInt(String(props.value)).toString();
	if (typeInput === 'float' && !isFocus) props.value = parseFloat(String(props.value)).toString();
	if (props.value === 'NaN') props.value = '';
	return (
		<>
			<input
				value={props.value || ''}
				autoComplete="off"
				autoCorrect="off"
				ref={ref}
				type="text"
				{...props}
				onChange={(e: any) => props.onChange?.(e)}
			/>{' '}
			{errorMsg || (props.error?.isErr && <span className="text-danger">{errorMsg || props.error.errMsg}</span>)}
		</>
	);
});

const Select = React.forwardRef<HTMLSelectElement, SelectOptionPorps>(function Select(_props, _ref): JSX.Element {
	let props = { ..._props };
	let ref: any = _ref;
	let newref = useRef<HTMLSelectElement>(null);
	if (!ref) ref = newref;

	props.className = classNames(
		`
      appearance-none
      border rounded w-full ${getSize(props.sizeinput)} px-3 
      text-gray-700 leading-tight
      focus:outline-primary-500 focus:shadow-outline] ${props.className}
      focus:invalid:border-danger
   `,
		{
			' border-[#b3b3b3]': !props.error?.isErr,
			'border-danger': props.error?.isErr,
		},
	);
	if (props.type === undefined) props.type = 'text';
	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();
	let errorMsg = (state?.err || []).find(v => v.name === props.name)?.message;
	useEffect(
		function () {
			if (providerInitialized) changeForm(props.name || '', ref?.current?.value || '');
		},
		[providerInitialized],
	);
	props.onChange = (e: React.FormEvent<HTMLSelectElement>) => {
		let target = e.target as HTMLInputElement;
		if (providerInitialized) {
			changeForm(target.name, target.value);
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);
	};

	if (providerInitialized) {
		props.value = (state.formData || {})[props.name as keyof typeof state.formData];

		if (props.name === undefined) throw new Error("<Input.Number name='' /> name attributte is required for data name");
	}
	useEffect(
		function () {
			if (providerInitialized) {
				initRefInput(props.name || '', ref);
			}
		},
		[props.name],
	);

	return (
		<>
			<select value={props.value || ''} autoComplete="off" autoCorrect="off" ref={ref} {...props} onChange={(e: any) => props.onChange?.(e)}>
				<option className="text-gray-300" value={''}>
					{props.placeholder || '-- Select --'}
				</option>
				{props.children}
			</select>
			{errorMsg || (props.error?.isErr && <span className="text-danger">{errorMsg || props.error.errMsg}</span>)}
		</>
	);
});

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea({ onChangeValue, ..._props }, _ref) {
	let props = { ..._props };
	let ref: any = _ref;
	let newref = useRef<HTMLTextAreaElement>(null);
	if (!ref) ref = newref;
	props.className = classNames(
		`
      appearance-none
      border rounded w-full py-2 px-3 
      text-gray-700 leading-tight
      focus:outline-primary-500 focus:shadow-outline] ${props.className}
      focus:invalid:border-danger
   `,
		{
			' border-[#b3b3b3]': !props.error?.isErr,
			'border-danger': props.error?.isErr,
		},
	);
	if (props.type === undefined) props.type = 'text';

	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();

	useEffect(
		function () {
			if (providerInitialized) changeForm(props.name || '', ref?.current?.value || '');
		},
		[providerInitialized],
	);
	props.onChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
		let target = e.target as HTMLTextAreaElement;
		if (providerInitialized) {
			changeForm(target.name, target.value);
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);
	};
	if (providerInitialized) {
		props.value = (state.formData || {})[props.name as keyof typeof state.formData];
		if (props.name === undefined) throw new Error("<Input.Text name='' /> name attributte is required for data name");
	}
	useEffect(function () {
		if (providerInitialized) {
			initRefInput(props.name || '', ref);
		}
	}, []);

	return (
		<>
			<textarea
				value={props.value || ''}
				autoComplete="off"
				autoCorrect="off"
				ref={ref}
				{...props}
				onChange={(e: any) => {
					props.onChange?.(e);
					if (typeof onChangeValue === 'function') onChangeValue(e.targe?.value);
				}}
			/>
			{props.error?.isErr && <span className="text-danger">{props.error.errMsg}</span>}
		</>
	);
});

const CheckBox = React.forwardRef<HTMLInputElement, InputCheckBoxProps>(function CheckBox(_props, _ref) {
	let props = { ..._props };
	let ref: ForwardedRef<HTMLInputElement> = _ref;
	let newref = useRef<HTMLInputElement>(null);
	if (!ref) ref = newref;
	props.className = `
   w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 cursor-pointer
    ${props.className} mr-3
   `;
	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();

	useEffect(
		function () {
			// if (providerInitialized)
			// changeForm(props.name || "", props.checked || "");
		},
		[providerInitialized],
	);
	props.onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
		let target = e.target as HTMLInputElement;
		if (providerInitialized) {
			changeForm(target.name, target.checked);
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);
	};
	if (providerInitialized) {
		if (props.name === undefined) throw new Error("<Input.Text name='' /> name attributte is required for data name");
		props.checked = (state.formData || {})[props.name as keyof typeof state.formData];
	}
	useEffect(
		function () {
			if (providerInitialized) initRefInput(props.name || '', ref);
		},
		[props.name],
	);
	return (
		// <div className="flex items-center">
		<input type={'checkbox'} value={props.value || ''} ref={ref} checked={props.checked} {...props} onChange={(e: any) => props.onChange?.(e)} />
		// {props.children}
		// </div>
	);
});

const AsyncSelect2 = React.forwardRef<AsyncSelect, AsyncSelect2Props>(function AsyncSelect(_props, _ref): JSX.Element {
	let props = { ..._props };
	let ref: ForwardedRef<AsyncSelect> = _ref;
	let newref = useRef<AsyncSelect>(null);
	if (!ref) ref = newref;
	const {
		state,
		actions: { initRefInput = () => null, changeForm },
		providerInitialized,
	} = useForm();
	const [options, setDataOptions] = useState<Array<{ value: string; label: string }>>([]);

	let errorMsg = (state?.err || []).find(v => v.name === props.name)?.message;
	useEffect(
		function () {
			if (providerInitialized) changeForm(props.name || '', props.value || '');
		},
		[providerInitialized],
	);
	let opt: Array<{ value: string; label: string }> = [];
	if (props.options) opt = props.options;
	props.onChange = e => {
		// let target = e.target as HTMLInputElement;
		if (providerInitialized) {
			changeForm(props.name || '', e.value);
		}
		if (typeof _props.onChange == 'function') _props.onChange(e);
	};
	if (providerInitialized) {
		props.value = (state.formData || {})[props.name as keyof typeof state.formData];

		if (props.name === undefined) throw new Error("<Input.Text name='' /> name attributte is required for data name");
	}
	useEffect(
		function () {
			if (providerInitialized)
				initRefInput(props.name || '', {
					current: {
						...props,
					},
				});
		},
		[props.name],
	);
	let place: Array<{ value: string; label: string }> = [
		{
			value: '',
			label: ` -- Select ${props.placeholder || ''} --`,
		},
	];
	let height = 31;
	const filter: any = async (inputValue: string) => {
		return [
			...place,
			...opt.filter(
				i =>
					i.label.toLowerCase().includes(String(inputValue || '').toLowerCase()) ||
					i.value.toLocaleLowerCase().includes(String(inputValue || '').toLocaleLowerCase()),
			),
		];
	};
	if (props.value) {
		let data = options;
		props.value = data?.find(v => v.value === props.value);
		props.defaultInputValue = props.value?.label;
	} else {
		props.value = place[0];
	}
	// },[])
	props.styles = {
		...props.styles,
		control: styles => {
			// console.log(styles)
			return {
				...styles,
				minHeight: 10,
				height,
				padding: 0,
				...(props.error?.isErr
					? {
							border: '#dc3545 1px solid',
					  }
					: {}),
			};
		},
		placeholder: styles => {
			return {
				...styles,
				top: 13,
				// position:"static",
				// paddingTop:20
			};
		},
		input: styles => {
			// console.log(styles)
			return {
				...styles,
				paddingBottom: 10,
				position: 'absolute',
				// height:10,
				// margin:3
			};
		},
		indicatorsContainer: s => {
			return {
				...s,
				padding: '4px 1px',
				height,
			};
		},
		valueContainer: styles => ({
			...styles,
			height: 20,
			minHeight: 10,
			position: 'static',
		}),
		indicatorSeparator: style => ({
			display: 'none',
		}),
		container: styles => ({
			...styles,
			height,
			minHeight: 10,
			borderRadius: 1,
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				color: isFocused ? 'white' : 'black',
				cursor: isDisabled ? 'not-allowed' : 'default',
				padding: 1,
				paddingLeft: 10,
				backgroundColor: isFocused ? '#007bff' : 'default',
				fontSize: 10,
				innerHeight: 6,
				// height: 20
			};
		},
	};

	const [src, setSrc] = useState('');
	const [optionLoaded, setOptLoaded] = useState(false);
	const [loading, setLoading] = useState(false);

	const [focused, setFocused] = useState(false);
	const [dataSelected, setDataSelected] = useState<{
		value: string;
		label: string;
	}>({
		label: '',
		value: '',
	});
	props.onFocus = function (e) {
		if (typeof _props.onFocus === 'function') _props.onFocus?.(e);
		if (props.withCallApi && !optionLoaded) search();
	};
	async function search(withSearch = true) {
		setLoading(true);
		if (props.asyncLoadData && props.withCallApi) {
			let data = await props.asyncLoadData?.(withSearch ? src : '');
			let newSelected: { value: string; label: string } | undefined = dataSelected;
			if (props.valueDisplay === '') {
				newSelected = data.find(v => v.value === props.value);
				if (!newSelected) newSelected = dataSelected;
			}
			let dataX: Array<{ value: string; label: string }> = [];
			if (newSelected && !data.some(v => v.value === newSelected?.value)) {
				dataX = [dataSelected];
			}
			setDataOptions([...dataX, ...data]);
		} else {
			let data = await filter(src);
			setDataOptions(data);
		}
		// setSrc("")
		setOptLoaded(true);
		setLoading(false);
	}

	useEffect(
		function () {
			let sel = options?.find(v => v.value === props.value);
			if (options.length <= 2)
				setDataOptions([
					...place,
					{
						label: props.valueDisplay || '',
						value: props.value,
					},
				]);
			if (props.value !== '') {
				setDataSelected({
					value: props.value,
					label: sel ? sel.label : props.valueDisplay || '',
				});
			}
			if (props.value === '' || props.value === undefined || props.value === null) {
				setDataSelected(place[0]);
			}
		},
		[props.value, props.valueDisplay],
	);

	return (
		<>
			<div>
				<AsyncSelect
					// id="long-value-select"
					// instanceId="long-value-select"
					// defaultValue={dataSelected}
					value={dataSelected}
					// escapeClearsValue
					// onBlur={() => {
					//    setFocused(false);
					// }}
					// onKeyDown={(e : any) => {
					//    if (props.enterSearch) {
					//       if (e.keyCode === 13) {
					//          e.preventDefault();
					//          search();
					//       }
					//    }
					// }}
					// isSearchable={true}
					{...(props.enterSearch
						? {
								filterOption: (opt: any, input: any) => true,
								inputValue: src,
								onInputChange: (e: any) => setSrc(e),
						  }
						: {})}
					// onInputChange={ e => enterSearch ? setSrc(e) : true}
					placeholder={props.enterSearch && focused ? `Enter for search` : ` -- Select ${props.placeholder || ''} --`}
					// defaultOptions ={options}
					// isLoading={loading}
					// loadOptions={loadOptions}
					options={options}
					{...props}
					// filterOption={(opt, input)=>true}
					// defaultValue={props.value | ""}
					// escapeClearsValue
					// onKeyDown={(e) => {
					//    if(e.keyCode ===13){
					//       e.preventDefault();
					//       search()
					//    }
					// }}
					// isSearchable = {true}
					// inputValue={src}
					// onInputChange = {(e) =>setSrc(e)}
					// placeholder={` -- Select ${props.placeholder || "" } --`}
					// // defaultOptions ={options}
					// isLoading={loading}
					// // loadOptions={loadOptions}
					// options={options}
					// {...props}
				/>
			</div>{' '}
			{errorMsg || (props.error?.isErr && <span className="text-danger">{errorMsg || props.error.errMsg}</span>)}
		</>
	);
});

function Label({ children, ..._props }: { required?: boolean } & HTMLProps<HTMLLabelElement>) {
	let props = { ..._props };
	const {
		state: { refInput = {} },
		actions: { initRefInput = () => null },
		providerInitialized,
	} = useForm();

	props.className = `text-[9pt] after:text-danger-300 after:ml-0.5`;
	if (providerInitialized) {
		if (props.htmlFor === undefined) throw new Error("<Label htmlFor=''> : htmlFor attribute is required for input name");
		props.className = `${props.className}  ${classNames({
			"after:content-['*']": refInput[props.htmlFor]?.current?.required || props.required,
		})}`;
	}
	return (
		<div className={`mb-1 pl-1 ${props.className}`}>
			<label {...props}>{children}</label>
		</div>
	);
}

const Input = {
	Text,
	Label,
	Select,
	Number,
	TextArea,
	CheckBox,
	// AsyncSelect2,
};
export default Input;
