import React, { SyntheticEvent, useEffect } from "react";
import useLogin, { LoginProvider } from "./login.provider";
import { Button, Card, GenerateForm, Input, LoadingPage } from "components";
import useAuth from "redux-tools/slices/auth/auth.hook";
import { Navigate } from "react-router";

function LoginPage() {
   const { formData } = useLogin();
   const { authState, authAction } = useAuth();

   if (authState.isAuthenticated) return <Navigate to="/" />;
   return (
      <>
         <LoadingPage isLoading={authState.isLoading}>Please Wait</LoadingPage>
         <div className="flex flex-wrap items-center justify-center w-screen min-h-screen bg-sidebar">
            <div className=" w-[30%] max-lg:w-[50%] max-md:w-[80%] shadow-2xl py-16 max-md:px-10 px-16 border rounded-xl">
               <div className="py-5 mb-5">
                  <img
                     src="/img/logo-putih.png"
                     className="w-[80%] max-md:w-[80%] mx-auto mb-2"
                  />
                  <h4 className="text-white text-center font-bold max-md:text-sm text-2xl">
                     Login in your account
                  </h4>
               </div>
               <div className="bg-slate-100/0 rounded-md p-5 max-md:p-2">
                  <form onSubmit={(e: SyntheticEvent<HTMLFormElement>)=> {
                     e.preventDefault();
                     authAction.doLogin(formData.formState);

                  }}>
                     <div className="grid grid-cols-1 gap-2 text-white">
                        <div>
                           <label>Username or Email</label>
                           <GenerateForm
                              otherProps={{
                                 className: "py-3 text-lg mt-1",
                              }}
                              type="string"
                              name="email"
                           />
                        </div>
                        <div>
                           <label>Password</label>
                           <GenerateForm
                              type="password"
                              otherProps={{
                                 className: "py-3 text-lg",
                              }}
                              name="password"
                           />
                        </div>
                        <div>
                           <Button type="submit"
                           >
                              Login
                           </Button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   );
}

export default (props: any) => (
   <LoginProvider>
      <LoginPage />
   </LoginProvider>
);
