import axios, { AxiosHeaders, AxiosInstance } from "axios";
import { appConfig } from "config/app.config";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";

let cookie = new Cookies();
export default function axiosWithAuth(headers: object = {}): AxiosInstance {
   let token = cookie.get("access_token");
   let newHeaders = {
      ...headers,
      Authorization: `Bearer ${token}`,
   };
   return axios.create({
      headers: newHeaders,
   });
}

interface DefaultResp<T = any> {
   code: "00" | "01" | "02" | "03" | "04";
   message: string;
   data: T;
}
interface P<T> {
   body?: T;
   header: AxiosHeaders;
   method: "POST" | "GET" | "PUT" | "DELETE";
   url: string;
   queryParam?: object;
   baseUrl?: string;
   withAlert?:boolean;
   callback: <T>(p: DefaultResp<T>) => any;
}

/**
 * 
 * @param p = parameter request { body, header, method, url, baseurl, queryparam dll } 
 * @returns typeof TResp on declare in -> { code, message, data }
 * 
 #### data = typeof TResp,
 #### code = 00 | 01 | 04 | 03 | 02
 @enum 00 = success
 @enum 01 = Error with warning or bad request
 @enum 02 = Error server internal
 @enum 03 = Error unknown
 @enum 04 = Error with not found
 
 #### message = Message of error from api

 @example
 ```typescript
   // type of requested data
   type TReq = { page: number; limit:number }

   // type of response data 
   type TResp = Array<{ id: number; title: string; name: string; total: number }>
   
   async function getData(){ 
      try {
         let resp = await doRequest<TReq, TResp>({
            url: "/test/to/path/endopoint",
            // body must be typeof TReq;
            body: { page:1, limit: 10 }
         })

         // resp is typeof { code:string, message: string, data: typeof TResp };
         if(resp.code === "00") return resp.data;
         else return []
      } catch (error:any) {
         console.log(error.toSrting)
         return []
      }
   } 
 ```
 */
export async function doRequest<TReq = {}, TResp = any>(
   p: P<TReq>
): Promise<DefaultResp<TResp | any>> {
   let respx = <DefaultResp<TResp>>{};
   try {
      let reqAxios = axiosWithAuth();
      let uri = p.url;
      if (p.queryParam !== undefined && p.queryParam !== null) {
         var keys = Object.keys(p.queryParam);
         keys.forEach((val, key) => {
             if (key == 0) uri = uri + '?';
             uri = uri + (val + '=' + p.queryParam?.[val as keyof typeof p.queryParam]);
             if (key != keys.length - 1) uri = uri + '&';
         });
     }
      const resp = await reqAxios({
         baseURL: p.baseUrl ?? appConfig.BASE_URL_API,
         url: uri,
         method: p.method,
      });
      const { data } = resp;
      if (resp.status === 200) {
         respx = {
            code: data.code as DefaultResp<TResp>["code"],
            message: data.message as DefaultResp<TResp>["message"],
            data: data.data as DefaultResp<TResp>["data"],
         };
         if (data.code !== "00" && data.code !== "04") {
            if(p.withAlert) Swal.fire("Info", data.message, "info");
         }
      } else {
         if (resp.status === 401) {
            Swal.fire(
               "Unauthorize",
               "<h4> Session habis atau belum login, silahkan login kembali </h4>",
               "warning"
            ).then((v) => {
               cookie.remove("access-token");
               window.location.href = "/login";
            });
         } else {
            if(p.withAlert) Swal.fire("Info", data.message, "info");
            respx = {
               code: data.code as DefaultResp<TResp>["code"],
               message: data.message as DefaultResp<TResp>["message"],
               data: data.data as DefaultResp<TResp>["data"],
            };
            return respx;
         }
      }
      p.callback(respx);
      return respx;
   } catch (error: any) {
      respx = {
         code: "03",
         message: error.toSrting(),
         data:undefined as DefaultResp<TResp>["data"],
      }
      p.callback<TResp | any>(respx);
      if(p.withAlert) Swal.fire("Info", error.toSrting(), "info");
      return respx;
   }
}
